import { get,put,Delete } from '../axios'

// 配置订单-列表
export const configureOrderList = params => get('/web/configure/order/list',params);

// 配置订单-状态-修改
export const configureOrderState = params => put('/web/configure/order/state/update',params);

// 配置订单-删除
export const configureOrderDelete = params => Delete(`/web/configure/order/delete/${params}`);

// 配置订单下-产品列表
export const configureProductList = params => get('/web/configure/order/product/list',params);

// 配置订单-导出
export const configureOrderExport = params => get(`/web/configure/order/export/${params}`);