import {get, post, put,Delete} from '../axios'
// 接收邮件-列表
export const emailList = params => get('/web/system/email/list',params);

// 接收邮件-新增
export const emailAdd = params => post('/web/system/email/add',params);

// 接收邮件-查看
export const emailView = params => get(`/web/system/email/view/${params}`);

// 接收邮件-修改
export const emailUpdate = params => put('/web/system/email/update',params);

// 接收邮件-删除
export const emailDelete = params => Delete(`/web/system/email/delete/${params}`);