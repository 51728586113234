<template>
    <div class="mainPage">
        <div class="login" :style="{ width: screenWidth > 1000 ?'1000px': divWidth + 'px' }">
            <div class="login-left" v-show="screenWidth > 1000"></div>
            <div class="login-right" :style="{ width: screenWidth > 1000 ?'50%': '100%' }">
                <div class="login-right-title">登录</div>
                <div class="form-submit">

                    <el-form class="login-form"
                             status-icon
                             :rules="loginRules"
                             ref="loginForm"
                             :model="loginForm"
                             label-width="0">
                        <el-form-item prop="username">
                            <el-input size="small"
                                      @keyup.enter.native="handleLogin"
                                      v-model="loginForm.username"
                                      auto-complete="off"
                                      placeholder="请输入用户名">
                                <i slot="prefix" class="iconfont icon-user1"/>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input size="small"
                                      @keyup.enter.native="handleLogin"
                                      :type="passwordType"
                                      v-model="loginForm.password"
                                      auto-complete="off"
                                      placeholder="请输入密码">
                                <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword"/>
                                <i slot="prefix" class="iconfont icon-password"/>
                            </el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"
                                       size="small"
                                       @click.native.prevent="handleLogin"
                                       class="login-submit">登录
                            </el-button>
                        </el-form-item>
                    </el-form>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {login, userinfo} from "../../api";
    export default {
        name: "login",
        data(){
            return{
                screenWidth: '',
                divWidth: '',
                loginForm: {
                    username: "",
                    password: "",
                },
                loginRules: {
                    username: [
                        {required: true, message: "请输入用户名", trigger: "blur"}
                    ],
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 1, message: "密码长度最少为6位", trigger: "blur"}
                    ]
                },
                passwordType: 'password',
            }
        },
        created() {
            this.screenWidth = document.body.clientWidth;
            if(document.body.clientWidth <= 1000){
                this.divWidth = document.body.clientWidth * 1 - 40
            }
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth;
                    this.divWidth = document.body.clientWidth * 1 - 40
                })();
            };
        },
        methods:{
            showPassword() {
                this.passwordType === ""
                    ? (this.passwordType = "password")
                    : (this.passwordType = "");
            },
            handleLogin() {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        const loading = this.$loading({
                            lock: true,
                            text: '登录中,请稍后。。。',
                            spinner: "el-icon-loading"
                        });
                        login({
                            username: this.loginForm.username,
                            password: this.loginForm.password
                        }).then(res => {
                            // 获取数据成功后的其他操作
                            if (res.code == 200){
                                this.$router.push('/')
                                this.$store.commit("setToken",res.data.token);
                                loading.close();
                            }else{
                                this.$message.error(res.msg);
                                loading.close();
                            }
                        }).catch((err) => {
                            loading.close();
                            this.$message.error(err.msg);
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
.mainPage{
    width: 100%;
    height: 100vh;
    background: #049ec4;
}
.login{
    height: 500px;
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%,-50%);
    display: flex;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: -4px 5px 10px rgba(0,0,0,0.4);

}
.login-left{
    width: 50%;
    background-color: #409eff;
}
.login-right {
    height: auto;
    background-color: #ffffff;
}
.login-right-title{
    color: #333;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 4px;
    padding: 50px 0 40px;
    box-sizing: border-box;
}
.form-submit{
    width: 325px;
    margin: 0 auto;
}
.form-submit >>> .el-input__inner{
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}
.login-submit {
    width: 100%;
    height: 45px;
    border: 1px solid #409EFF;
    background: none;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 300;
    color: #409EFF;
    cursor: pointer;
    margin-top: 30px;
    font-family: "neo";
    transition: 0.25s;
}
</style>