<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form ref="form"
                   :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-after="uploadAfter"
                   @submit="formSubmit">

        </avue-form>
    </el-dialog>
</template>

<script>
    import { seriesAdd,seriesView,seriesUpdate } from '@/api/product/series';
    export default {
        name: "index",
        props: {
            title: {
                default: '新增',
                type: String
            },
            id: {
                default: '',
                type: String
            },
            parentId: {
                default: '0',
                type: String
            },
            isFrame: {
                default: 0,
                type: Number
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: {
                    isFrame: this.isFrame
                },
                option: {
                    submitBtn: this.title != '查看' ? true : false,
                    emptyBtn: this.title == '新增' || this.title == '新增子项' ? true : false,
                    column:[
                        {
                            label: '排序',
                            prop: 'sort',
                            type: 'number',
                            rules: [{
                                required: true,
                                message: "请输入排序",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '分类名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '边框',
                            prop: 'isFrame',
                            type: 'radio',
                            dicData: [{label: '自带',value: 1},{label: '不带',value: 2},{label: '无边框',value: 3}],
                            rules: [{
                                required: true,
                                message: "请选择是否带边框",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' || this.parentId != 0 ? true : false
                        },
						{
						    label: '联排数量',
						    prop: 'frameNum',
						    type: 'number',
						    rules: [{
						        required: true,
						        message: "请输入联排数量",
						        trigger: "blur"
						    }],
						    disabled: this.title == '查看' ? true : false
						}
                    ],
					
                }
            }
        },
        mounted() {
            if(this.title != '新增' && this.title != '新增子项'){
                seriesView(this.id).then( res => {
                    if(res.code === 200){
                        this.form = res.data;
                    }
                })
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增' || this.title == '新增子项'){
                    this.formSubmitAdd(form,done);
                }else{
                    this.formSubmitEdit(form,done);
                }
            },
            formSubmitAdd(form,done){
                seriesAdd({
                    name: form.name,
                    parentId: this.parentId,
                    sort: form.sort,
                    isFrame: form.isFrame,
					frameNum: form.frameNum
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeSeries', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            formSubmitEdit(form,done){
                seriesUpdate({
                    id: this.id,
                    name: form.name,
                    parentId: this.parentId,
                    sort: form.sort,
                    isFrame: form.isFrame,
					frameNum: form.frameNum
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeSeries', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeSeries', false)
                    })
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadAfter(res, done) {
                // this.$message.success('上传后的方法')'
                done()
                this.$nextTick(() => {
                    this.form.image = res.path;
                })
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            }
        }
    }
</script>

<style scoped>

</style>