import { get,post,put,Delete } from '../axios'

/* 配置方案-列表 */
export const schemeList = params => get('/web/product/scheme/list',params);

// 配置方案-新增
export const schemeAdd = params => post('/web/product/scheme/add',params);

// 配置方案-删除
export const schemeDelete = params => Delete(`/web/product/scheme/delete/${params}`);

// 配置方案-查看
export const schemeView = params => get(`/web/product/scheme/view/${params}`);

// 配置方案-修改
export const schemeUpdate = params => put('/web/product/scheme/update',params);
