var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"60%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('avue-form',{attrs:{"option":_vm.option,"upload-error":_vm.uploadError,"upload-delete":_vm.uploadDelete,"upload-after":_vm.uploadAfter},on:{"submit":_vm.formSubmit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"details"},[_c('avue-crud',{attrs:{"data":_vm.paramData,"option":_vm.option1},on:{"sortable-change":_vm.sortableChange},scopedSlots:_vm._u([{key:"key",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.key),expression:"row.key"}],attrs:{"type":"text","disabled":_vm.title == '查看' ? true : false},domProps:{"value":(row.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "key", $event.target.value)}}})]}},{key:"value",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.value),expression:"row.value"}],attrs:{"type":"text","disabled":_vm.title == '查看' ? true : false},domProps:{"value":(row.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "value", $event.target.value)}}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(index == 0)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus-outline"},on:{"click":function($event){$event.stopPropagation();return _vm.clickAdd.apply(null, arguments)}}},[_vm._v("添加")]):_vm._e(),(index != 0)?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-circle-close"},on:{"click":function($event){$event.stopPropagation();return _vm.clickCel(index)}}},[_vm._v("删除")]):_vm._e()]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }