<template>
    <div class="main-content">
        <avue-crud :data="data"
                   :option="option"
                   v-model="form">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           icon="el-icon-plus"
                           @click.stop="clickMenu('新增子项',row)">新增子项</el-button>
            </template>

        </avue-crud>

        <SeriesIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :id="id" :parentId="parentId" :isFrame="isFrame"/>
    </div>
</template>

<script>
    import { seriesList,seriesDelete } from '@/api/product/series';
    import SeriesIndex from "@/components/product/series/index";

    export default {
        name: 'series',
        components: {
            SeriesIndex
        },
        data() {
            return {
                Buttons: [],
                title: '',
                id: '',
                parentId: '',
                isFrame: 0,
                dialogVisible: false,
                form: {
                    image: ''
                },
                data: [],
                option:{
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    addBtn:false,
                    editBtn:false,
                    delBtn:false,
                    menuWidth: 300,
                    column:[
                        {
                            label: '排序',
                            prop: 'sort',
                            type: 'number',
                            rules: [{
                                required: true,
                                message: "请输入排序",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: '分类名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                            display: false
                        }
                    ]
                },
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;

            this.GetSeriesList();
            this.$root.eventHub.$on('changeSeries', (n) => {
                this.dialogVisible = false
                if(n){
                    this.GetSeriesList();
                }
            })
        },
        methods: {
            GetSeriesList() {
                seriesList().then(res => {
                    if (res.code === 200) {
                        // console.log(res);
                        this.data = res.data;
                    }
                });
            },
            clickMenu(name,form){
                // console.log(name,form)
                this.title = name;
                if(name == '删除'){
                    this.rowDel(form.id);
                }else if(name == '新增子项'){
                    this.parentId = form == undefined ? '0': form.id;
                    this.isFrame = form.isFrame;
                    this.dialogVisible = true;
                }else{
                    this.id = form == undefined ? '0': form.id;
                    this.parentId = form == undefined ? '0': form.parentId;
                    this.dialogVisible = true;
                }
            },
            rowDel(id){
                this.$confirm(`删除这条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            seriesDelete(id).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.GetSeriesList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>