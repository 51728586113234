<template>
    <el-dialog
            title="选择商品"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">

        <avue-crud :data="goodsData"
                   :option="goodsOption"
                   :page.sync="pageSync"
                   :table-loading="loading"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @on-load="onLoad">

            <template slot-scope="{row}" slot="menu">
                <div style="cursor: pointer" @click.once="clickChoice(row)">选择</div>
            </template>
        </avue-crud>

    </el-dialog>
</template>

<script>
    import { imageUrl } from '@/config/public';
    import { productList,productTree,classifyTree,productView } from "@/api/product/list";
    import SchemeSpec from '@/components/product/scheme/spec.vue';
    export default {
        name: "index",
        props: {
            goodsID: String,
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        components: {
            SchemeSpec
        },
        data(){
            return{
                loading: true,
				search:{},
                goodsData: [],
                goodsOption:{
                    header: false,
                    showHeader: false,
                    border: true,
                    align:'center',
                    menuAlign:'center',
                    addBtn: false,
                    editBtn: false,
                    delBtn:false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新,
                    menuWidth: 100,

					searchMenuSpan: 6,
					searchIndex:3,
					searchIcon:true,
					searchShowBtn:false,
                    column:[
                        {
                            label: '主图',
                            prop: 'mainGraph',
                            type: 'upload',
                            listType: 'picture-img',
                            width: 100,
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label:'名称',
                            prop:'name',
							search: true
                        },
						{
                            label:'产品分类',
                            prop:'classifyId',
                            search: true,
                            hide: true,
                            rules: [{
                                message: "请输入产品分类",
                                trigger: "blur"
                            }],
                            filter: false,
                            type: 'cascader',
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '系列分类',
                            prop: 'seriesClassifyId',
                            search: true,
                            hide: true,
                            rules: [{
                                message: "请输入系列分类",
                                trigger: "blur"
                            }],
                            filter: false,
							type: "cascader",
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            search: true,
                            type: 'radio',
                            hide: true,
                            dicData: [{label: '上架',value: 1},{label: '下架',value: 2}],
                            mock:{
                                type:'dic'
                            }
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                typeId: '',
                expandFrom: {}
            }
        },
		mounted() {
			this.GetProductTree();
			this.GetClassifyTree();
		},
        methods: {
			// 产品分类
			GetProductTree(){
			    productTree().then(res => {
			        // console.log(res)
                    if(res.code === 200){
                        const column = this.findObject(this.goodsOption.column, "classifyId");
                        column.dicData = res.data;
                        this.$store.commit('SetProductTree',res.data)
			        }
			    })
			},
			// 系列分类
			GetClassifyTree(){
			    classifyTree().then( res => {
			        if(res.code === 200){
			            const column = this.findObject(this.goodsOption.column, "seriesClassifyId");
			            column.dicData = res.data;
			            this.$store.commit('SetClassifyTree',res.data)
			        }
			    })
			},
			searchChange(params,done) {
				// console.log(params)
				if(params.classifyId != undefined){
					params.classifyId.forEach((item,index) => {
						if(index == params.classifyId.length - 1){
							this.search.classifyIdLast = item
						}
					})
				}else{
					this.search.classifyIdLast = ''
				}
				if(params.seriesClassifyId != undefined){
					params.seriesClassifyId.forEach((item,index) => {
						if(index == params.seriesClassifyId.length - 1){
							this.search.seriesClassifyIdLast = item
						}
					})
				}else{
					this.search.seriesClassifyIdLast = ''
				}
                this.search.name = params.name;
                this.search.state = params.state;
                this.pageSync.currentPage = 1;
                // console.log('this.search',this.search)
			    this.list()
			    done();
			},
			searchReset(params){
			    // console.log(params)
				this.search.classifyIdLast = '';
				this.search.seriesClassifyIdLast = '';
                this.search.name = '';
                this.search.state = '';
                this.list()
			},
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeSchemeIndex', false);
                    })
            },
            onLoad(){
                this.list();
            },
            list(){
                productList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name,
                    classifyId: this.search.classifyIdLast,
                    seriesClassifyId: this.search.seriesClassifyIdLast,
                    state: this.search.state,
                }).then(res => {
                    if(res.code === 200){
                        this.$nextTick(()=>{
                            this.loading = false;
                            this.goodsData = res.data.records;
                            this.pageSync.currentPage = res.data.current;
                            this.pageSync.total = res.data.total;
                        })
                    }
                })
            },
            clickChoice(form){
                this.$emit('clickChoice',form.id)
            }
        }
    }
</script>

<style scoped>
    .active{
        color: #409eff;
    }
</style>
