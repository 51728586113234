<template>
    <div class="main-content">
        <avue-crud :data="data" :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @selection-change="selectionChange"
                   @search-change="searchChange"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">

                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="row.state == 1 || item.name == '删除'"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.name == '删除' ? item.icon : item.name == '同意' ? 'el-icon-check': 'el-icon-close'"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

            </template>
			
			<template slot="avatarUrl" slot-scope="scope" >
				<img style="display: block;width: 40px;height: 40px;margin: 0 auto;" :src="scope.row.avatarUrl"></img>
			</template>

            <template slot="state" slot-scope="scope" >
                <el-tag>{{scope.row.state == 1 ? "待审核" : scope.row.state == 2 ? "已审核" : "已拒绝"}}</el-tag>
            </template>

        </avue-crud>

    </div>
</template>

<script>
    import { propertyApplyList,propertyApplyDelete,propertyApplyAgree,propertyApplyRefuse } from '@/api/person/propertyApply';
    import { imageUrl } from '@/config/public';
    export default {
        name: "propertyApply",
        data(){
            return{
                id: '0',
                title: '',
                dialogVisible: false,
                selectionList: [],
                Buttons: [],
                search: {},
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    searchIndex:3,
                    searchIcon:true,
                    align: 'center',
                    column:[
                        {
                            label: '头像',
                            prop: 'avatarUrl',
							slot: true
                        },
                        {
                            label: '姓名',
                            prop: 'username',
                            search: true
                        },
                        {
                            label: '昵称',
                            prop: 'nickname',
                            search: true
                        },
                        {
                            label: '邀请码',
                            prop: 'inviteCode',
                            search: true
                        },
                        {
                            label: '联系方式',
                            prop: 'phone',
                            search: true
                        },
                        {
                            label: '公司名称',
                            prop: 'companyName',
                            search: true
                        },
                        {
                            label: '省份',
                            prop: 'province',
                        },
                        {
                            label: '城市',
                            prop: 'city',
                            search: true
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            type: 'select',
                            search: true,
                            dicData: [
                                {
                                    label: "待审核",
                                    value: '1'
                                },
                                {
                                    label: "已审核",
                                    value: '2'
                                },
                                {
                                    label: "已拒绝",
                                    value: '3'
                                }
                            ],
                            slot:true,
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeCode', (n) => {
                this.dialogVisible = n.state;
                if(n.type){
                    this.onLoad();
                }
            })

        },
        methods: {
            searchChange(params,done) {
                // console.log(params)
                this.search.city = params.city == undefined ? '':params.city;
                this.search.companyName = params.companyName == undefined ? '':params.companyName;
                this.search.inviteCode = params.inviteCode == undefined ? '':params.inviteCode;
                this.search.nickname = params.nickname == undefined ? '':params.nickname;
                this.search.phone = params.phone == undefined ? '':params.phone;
                this.search.state = params.state == undefined ? '':params.state;
                this.search.username = params.username == undefined ? '':params.username;
                this.onLoad();
                done();
            },
            clickMenu(name,form){
                console.log(name,form)
                if(name == '同意'){
                    this.GetPropertyApplyAgree(form.id);
                }else if(name == '拒绝'){
                    this.GetPropertyApplyRefuse(form.id);
                }else{
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                propertyApplyDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetPropertyApplyList();
            },
            GetPropertyApplyList(){
                propertyApplyList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    city: this.search.city,
                    companyName: this.search.companyName,
                    inviteCode: this.search.inviteCode,
                    nickname: this.search.nickname,
                    phone: this.search.phone,
                    state: this.search.state,
                    username: this.search.username,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            },
            GetPropertyApplyAgree(id){
                this.$confirm(`确定同意`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            propertyApplyAgree(id).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.GetPropertyApplyList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            GetPropertyApplyRefuse(id){
                this.$confirm(`确定拒绝`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            propertyApplyRefuse(id).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.GetPropertyApplyList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>