import { get,Delete } from '../axios'

// 办事处申请-列表
export const propertyApplyList = params => get('/web/person/property/apply/list',params);

// 办事处申请-通过
export const propertyApplyAgree = params => get(`/web/person/property/apply/agree/${params}`);

// 办事处申请-拒绝
export const propertyApplyRefuse = params => get(`/web/person/property/apply/refuse/${params}`);

// 办事处申请-删除
export const propertyApplyDelete = params => Delete(`/web/person/property/apply/delete/${params}`);
