import {get, post, put,Delete} from '../axios'

// 角色管理-列表
export const menuList = params => get('/web/system/menu/list',params);

// 菜单管理-新增
export const menuAdd = params => post('/web/system/menu/add',params);

// 菜单管理-删除
export const menuDelete = params => Delete(`/web/system/menu/delete/${params}`);

// 菜单管理-查看
export const menuView = params => get(`/web/system/menu/view/${params}`);

// 菜单管理-修改
export const menuUpdate = params => put('/web/system/menu/update',params);

// 菜单管理-树形结构
export const menuTree = params => get('/web/system/menu/tree',params);
