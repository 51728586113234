<template>
    <div>
        <avue-crud :data="data" :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @selection-change="selectionChange"
                   @search-change="searchChange"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
<!--                <el-button style="margin-left:10px;"-->
<!--                           size="small"-->
<!--                           type="text"-->
<!--                           icon="el-icon-c-scale-to-original"-->
<!--                           @click.stop="clickCode(row)">邀请码</el-button>-->
            </template>


        </avue-crud>

        <Project v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :projectViewForm="projectViewForm"/>
    </div>
</template>

<script>
    import { projectList,projectDelete,projectView } from '@/api/person/project';
    import { officeRegionList } from '@/api/person/office';
    import Project from "@/components/person/project/project";
    export default {
        name: "project",
        components:{
            Project
        },
        data(){
            return{
                id: '0',
                title: '',
                dialogVisible: false,
                selectionList: [],
                Buttons: [],
                search: {},
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    align: 'center',
                    menuWidth: 300,
                    column:[
                        {
                            label: '名称',
                            prop: 'name',
                            search: true,
                        },
                        {
                            label: '大区名称',
                            prop: 'regionName',
                        },
                        {
                            label: '省份',
                            prop: 'province',
                        },
                        {
                            label: '市区',
                            prop: 'city',
                        },
                        {
                            label: '县区',
                            prop: 'county',
                        },
                        {
                            label: '大区',
                            prop: 'regionId',
                            search: true,
                            type: 'select',
                            hide: true,
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                            rules: [{
                                message: "请选择大区",
                                trigger: "blur"
                            }]
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                projectViewForm: {}
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeProject', (n) => {
                this.dialogVisible = false;
                this.projectViewForm = {};
                if(n){
                    this.onLoad();
                }
            })
            this.GetOfficeRegionList();
        },
        methods: {
            searchChange(params,done) {
                // console.log(params)
                this.search.dealer = params.dealer == undefined ? '':params.dealer;
                this.search.name = params.name == undefined ? '':params.name;
                this.search.regionId = params.regionId == undefined ? '':params.regionId;
                this.onLoad();
                done();
            },
            clickCode(form){
                // console.log('邀请码')
                this.$emit('goBack', false)
                this.$store.commit('SetId',form.id)
            },
            clickMenu(name,form){
                this.title = name;
                if(name == '新增'){
                    this.dialogVisible = true;
                }else if(name == '删除'){
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }else{
                    this.GetProjectView(form.id)
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                projectDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetProjectList();
            },
            GetProjectList(){
                projectList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name,
                    regionId: this.search.regionId
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            },
            GetOfficeRegionList(){
                officeRegionList().then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        const column = this.findObject(this.option.column, "regionId");
                        column.dicData = res.data;
                        this.$store.commit('SetRegionList',res.data)
                    }
                })
            },
            GetProjectView(id){
                projectView(id).then(res => {
                    console.log(res)
                    this.dialogVisible = true;
                    this.projectViewForm = res.data;
                })
            },
        }
    }
</script>

<style scoped>

</style>
