<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">

        <avue-form :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-after="uploadAfter"
                   @submit="formSubmit">

            <template slot="details">
                <avue-crud :data="paramData" :option="option1" @sortable-change="sortableChange">
                    <template slot="key" slot-scope="{row}">
                        <input type="text" v-model="row.key" :disabled="title == '查看' ? true : false">
                    </template>
                    <template slot="value" slot-scope="{row}">
                        <input type="text" v-model="row.value" :disabled="title == '查看' ? true : false">
                    </template>
                    <template slot-scope="{row,index}" slot="menu">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" v-if="index == 0" @click.stop="clickAdd">添加</el-button>
                        <el-button type="danger" icon="el-icon-circle-close" v-if="index != 0" @click.stop="clickCel(index)">删除</el-button>
                    </template>
                </avue-crud>
            </template>
        </avue-form>

    </el-dialog>
</template>

<script>
    import { imageUrl,actionUrl } from '@/config/public';
    import { paramAdd,paramUpdate,paramView } from "@/api/product/param";
    export default {
        name: "Param",
        props: {
            title: String,
            id: {
                default: '0',
                type: String
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: this.title != '查看' ? true : false,
                    emptyBtn: this.title == '新增' || this.title == '新增子项' ? true : false,
                    labelWidth: 120,
                    column: [
                        {
                            label: '名称',
                            prop: 'name',
                            span: 24,
                            rules: [{
                                required: true,
                                message: "请输入名称",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false,
                        },
                        {
                            label: '参数背景图',
                            prop: 'paramImage',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 24,
                            row: true,
                            loadText: '附件上传中，请稍等',
                            propsHttp:{
                                res:'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            rules: [{
                                required: true,
                                message: "请上传参数配置背景图",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '参数详情',
                            prop: 'details',
                            slot: true,
                            span: 24,
                            disabled: this.title == '查看' ? true : false
                        }
                    ]
                },
                paramData: [
                    {
                        key:'',
                        value:''
                    }
                ],
                option1: {
                    sortable:true,
                    dragHandler:true,
                    border: false,
                    editBtn: false,
                    delBtn: false,
                    align:'left',
                    menuAlign:'center',
                    menuWidth: this.title == '查看' ? 1 : 100,
                    column:[
                        {
                            label:'参数名称',
                            prop: 'key',
                            width: 150,
                            slot: true,
                        },
                        {
                            label:'内容',
                            prop: 'value',
                        }
                    ]
                }
            }
        },
        mounted() {
            if(this.id != 0){
                this.GetParamView();
            }
        },
        methods:{
            sortableChange(oldindex, newindex, row, list) {
                this.paramData=[]
                this.$nextTick(()=>{
                    this.paramData=list;
                })
                // console.log(oldindex, newindex, row, list)
            },
            clickAdd(){
                this.paramData.push({
                    key:'',
                    value:''
                })
            },
            clickCel(index){
                this.paramData.splice(index,1)
            },
            GetParamView(){
                paramView(this.id).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.form = res.data;
                        this.paramData = res.data.param
                    }
                })
            },
            formSubmit(form,done){
                if(this.title == '新增' || this.title == '新增子项'){
                    this.formSubmitAdd(form,done);
                }else{
                    this.formSubmitEdit(form,done);
                }
            },
            formSubmitAdd(form,done){
                paramAdd({
                    name: form.name,
                    paramImage: form.paramImage,
                    param: this.paramData
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeParam', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            formSubmitEdit(form,done){
                paramUpdate({
                    id: this.id,
                    name: form.name,
                    paramImage: form.paramImage,
                    param: this.paramData
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeParam', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeParam', false)
                    })
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            },
            uploadAfter(res, done) {
                done()
                this.$nextTick( () => {
                    this.form.paramImage = res.path;
                })
            },
        }
    }
</script>

<style scoped>
    .avue-form >>> .el-upload{
        width: 100px;
        height: 100px;
    }
    .avue-form >>> .el-icon-plus{
        width: 100px;
        height: 100px;
        line-height: 100px;
    }
    .avue-form >>> .avue-upload__avatar{
        width: 100px;
        height: 100px;
    }
    .avue-form >>> .el-upload-list__item,.el-upload-list__item-actions{
        width: 100px;
        height: 100px;
    }
    .avue-crud >>> .avue-crud__menu{
        height: 0;
        min-height: 0;
    }
    .avue-crud{
        border: 1px solid #E6E6E6;
    }
    .avue-crud >>> .avue-crud__menu{
        margin-bottom: 0px
    }
    .avue-crud >>> .el-table__cell .cell input{
        width: 80%;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #E6E6E6;
        padding: 0 10px;
    }
</style>
