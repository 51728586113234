import {  get,post,put } from './axios'
/* 登陆 */
export const login = params => post('/web/login', params);
/* 导航菜单列表 */
export const menuList = params => get('/web/index/menu',params);

/* 个人信息 */
export const userinfo = params => get('/web/index/user/info',params);

// 修改个人信息
export const useredit = params => put('/web/index/user/edit',params);

// 修改个人信息
export const userpassword = params => put('/web/index/user/password/update',params);


// 登出
export const logout = params => get('/web/index/logout',params);

// 获取省市区
export const getArea = params => get(`/web/tool/get/area/${params}`);

// 用户统计
export const countNum = params => post('/web/CountNum', params);

// 文件上传
export const fileUpload = params => post('/web/tool/file/upload', params);



