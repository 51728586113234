<template>
	<div class="spec">
		<div v-if="specList != null" v-for="(item,index) in specArray" :key="index">
			<span class="spec-button">{{item.key}}</span>
			<div style="height: 15px;"></div>
			<el-button 	v-for="(itemV,indexV) in item.value" :key="indexV"
						:type="itemV.type == true ? 'primary' : ''"
						@click.stop="clickSelect(index,itemV.content)"
						style="margin-bottom: 10px">{{itemV.content}}</el-button>
			<el-divider></el-divider>
		</div>
		<div v-if="specList == null">
			<span class="spec-button">选中规格</span>
			<el-divider></el-divider>
			<div v-for="(item,index) in submitSpec" :key="index">
				<span class="spec-button">{{item.key}}</span>
				<div style="height: 15px;"></div>
				<el-button type="primary">{{item.value}}</el-button>
				<el-divider></el-divider>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'spec',
		props: {
			index: Number,
			specList: Array,
			spec: Array,
		},
		data(){
			return{
				specArray: this.specList,
				submitSpec: this.spec
			}	
		},
		methods:{
			clickSelect(idx,content){
				let data = {
					key: '',
					value: ''
				}
				this.specArray.forEach( (item,i) => {
					if(i == idx){
						data.key = item.key;
						item.value.forEach( itemV => {
							if(itemV.content == content){
								itemV.type = true;
								data.value = itemV.content;
							}else{
								itemV.type = false;
							}
						})
					}
				})
				this.submitSpec[idx] = data;
                this.$root.eventHub.$emit('changeSchemeSpec', {index: this.index ,data:this.submitSpec})
			}
		}
	}
</script>

<style scoped>
	.spec{
		padding: 20px 100px;
	}
</style>
