<template>
	<el-dialog title="产品分类" :visible.sync="classifyData.type" width="60%" :before-close="handleClose">
		<avue-form ref="form" :option="option" v-model="form" @submit="formSubmit(form)">
		</avue-form>
	</el-dialog>
</template>

<script>
	import {
		productClassifyUpdate
	} from "@/api/product/list.js"
	export default {
		props: {
			classifyData: Object
		},
		data() {
			return {
				form: {
					ids: this.classifyData.list
				},
				option: {
					emptyBtn: false,
					labelWidth: 120,
					column: [{
						label: '产品分类',
						prop: 'classifyId',
						filter: false,
						type: "cascader",
						dicData: this.$store.state.product.productTree,
						props: {
							label: "name",
							value: 'id'
						},
						rules: [{
							required: true,
							message: '请选择产品分类',
							trigger: 'blur'
						}],
					}]
				}
			}
		},
		watch: {
			'form.classifyId'(value) {
				console.log(value)
				value.forEach((item, index) => {
					if (index == value.length - 1) {
						this.form.classify = item;
					}
				})
			}
		},
		methods: {
			formSubmit(form) {
				console.log(form)
				this.$refs.form.validate((vaild, done) => {
					if (vaild) {
					 productClassifyUpdate({
							ids: form.ids.toString(),
							classifyId: form.classify,
						 	classifyStr: form.classifyId.toString()
						}).then(res => {
							if (res.code === 200) {
								this.$message.success(res.msg);
								this.$root.eventHub.$emit('clickProductClassify', true);
							}else{
								this.$message.error(res.msg);
							}
							done()
						}).catch(err => {
							this.$message.error(err.msg);
							done()
						})
					}
				})
			},
			handleClose() {
				this.$confirm('确认关闭？')
					.then(res => {
						this.$root.eventHub.$emit('clickProductClassify', false);
					})
			}
		}
	}
</script>

<style>
</style>
