<template>
    <div class="main-content">
        <avue-crud :data="data"
                   :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @on-load="onLoad"
                   @selection-change="selectionChange">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

            <template slot="type" slot-scope="scope" >
                <div>{{scope.row.type == 1 ? "使用指南" : scope.row.type == 2 ? "关于我们" : "未知"}}</div>
            </template>

        </avue-crud>

        <Introduce v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :introduceViewForm="introduceViewForm"/>
    </div>
</template>

<script>
    import { introduceList,introduceView,introduceDelete } from "@/api/system/introduce";
    import Introduce from "@/components/system/introduce/introduce"
    export default {
        name: "introduce",
        components: {
            Introduce
        },
        data(){
            return{
                title: '',
                dialogVisible: false,
                introduceViewForm: {},
                selectionList: [],
                Buttons: [],
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    column:[
                        {
                            label: '类型',
                            prop: 'type',
                            slot: true
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                            width: 150,
                            align: 'center'
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeIntroduce', (n) => {
                this.dialogVisible = false;
                this.introduceViewForm = {};
                if(n){
                    this.onLoad();
                }
            })
        },
        methods: {
            clickMenu(name,form){
                this.title = name;
                if(name == '新增'){
                    this.dialogVisible = true;
                }else if(name == '删除'){
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }else{
                    this.GetIntroduceView(form.id)
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                introduceDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetIntroduceList();
            },
            GetIntroduceList(){
                introduceList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            },
            GetIntroduceView(id){
                introduceView(id).then(res => {
                    // console.log(res)
                    this.dialogVisible = true;
                    this.introduceViewForm = res.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>