<template>
	<div>
		<el-page-header @back="goBack" :content="title"></el-page-header>
		<el-divider></el-divider>
		<avue-form :option="option" v-model="form" :upload-error="uploadError" :upload-delete="uploadDelete" :upload-after="uploadAfter" @submit="formSubmit">
			<template slot="goods">
				<avue-crud :data="goodsData" :option="goodsOption" @expand-change="expandChanges">
					<template slot="menuLeft">
						<el-button type="primary" icon="el-icon-plus" size="small" @click.stop="clickAdd">新增</el-button>
					</template>
					<template slot="num" slot-scope="{ row }">
						<input
							type="number"
							v-model="row.num"
							min="1"
							pattern="[0-9]"
							:disabled="title == '查看' ? true : false"
							onkeyup="value=value.replace(/[\W]/g,'')"
							onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
						/>
					</template>
					
					<template slot="expand" slot-scope="{row,index}">
							<SchemeSpec :specList="row.specList" :spec="row.spec" :index="index"/>
					</template>

					<template slot-scope="{ row, index }" slot="menu">
						<el-button type="danger" icon="el-icon-circle-close" @click.stop="clickCel(index)">删除</el-button>
					</template>
				</avue-crud>
			</template>
			<template slot="details">
				<avue-ueditor v-model="uText" :options="uOptions"></avue-ueditor>
			</template>
		</avue-form>

		<SchemeIndex v-if="dialogVisible" :dialogVisible="dialogVisible" @clickChoice="clickChoice" />
	</div>
</template>

<script>
import { schemeAdd, schemeUpdate } from '@/api/product/scheme';
import { imageUrl, actionUrl } from '@/config/public';
import SchemeIndex from '@/components/product/scheme/index';
import SchemeSpec from '@/components/product/scheme/spec.vue';
import { productView } from '@/api/product/list';
export default {
	name: 'index',
	components: {
		SchemeIndex,
		SchemeSpec
	},
	props: {
		title: {
			default: '新增',
			type: String
		},
		schemeViewForm: Object
	},
	data() {
		return {
			dialogVisible: false,
			form: this.schemeViewForm,
			option: {
				submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
				emptyBtn: false,
				column: [
					{
						label: '主图',
						prop: 'mainGraph',
						type: 'upload',
						listType: 'picture-img',
						span: 24,
						loadText: '附件上传中，请稍等',
						action: actionUrl,
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						rules: [
							{
								required: true,
								message: '请上传图片',
								trigger: 'blur'
							}
						],
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '名称',
						prop: 'name',
						span: 12,
						rules: [
							{
								required: true,
								message: '请填写名称',
								trigger: 'blur'
							}
						],
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '排序',
						prop: 'sort',
						type: 'number',
						span: 12,
						rules: [
							{
								required: true,
								message: '请填写排序',
								trigger: 'blur'
							}
						],
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '状态',
						prop: 'state',
						type: 'radio',
						dicData: [{ label: '上架', value: 1 }, { label: '下架', value: 2 }],
						span: 12,
						rules: [
							{
								required: true,
								message: '请选择状态',
								trigger: 'blur'
							}
						],
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '商品',
						prop: 'goods',
						span: 24,
						slot: true
					},
					{
						label: '详情',
						prop: 'details',
						span: 24,
						slot: true
					}
				]
			},
			goodsData: this.title == '新增' ? [] : this.schemeViewForm.detail,
			goodsOption: {
				header: this.title == '查看' ? false : true,
				expand: true,
				expandRowKeys: [],
				rowKey: '$index',
				emptyText: '暂无商品',
				border: true,
				align: 'center',
				menuAlign: 'center',
				addBtn: false,
				editBtn: false,
				delBtn: false,
				columnBtn: false, //列显隐
				refreshBtn: false, //刷新,
				menuWidth: 100,
				column: [
					{
						label: '图片',
						prop: 'mainGraph',
						type: 'upload',
						listType: 'picture-img',
						propsHttp: {
							home: imageUrl
						}
					},
					{
						label: '名称',
						prop: 'name'
					},
					{
						label: '数量',
						prop: 'num',
						slot: true
					}
				]
			},
			choiceIndex: 0,
			uText: this.title == '新增' ? '' : this.schemeViewForm.details,
			uOptions: {
			    action: actionUrl,
			    headers: {
			        Authorization: this.$store.state.user.Token
			    },
			    customConfig: {},
			    props: {
			        url: "path",
			        res: "data",
			        name: 'path',
			        home: imageUrl
			    }
			},
		};
	},
	mounted() {
		this.$root.eventHub.$on('changeSchemeIndex', n => {
			this.dialogVisible = false;
		});

		this.$root.eventHub.$on('changeSchemeSpec', n => {
			this.goodsData[n.index].spec = n.data;
		});
	},
	methods: {
		formSubmit(form, done) {
			// console.log(form);
			if (this.goodsData.length == 0) {
				this.$message.error('请选择商品');
				done();
			} else {
				let result = this.goodsData.find(item => {
					return item.num == '';
				});
				if (result == undefined) {
					let type = true;
					for(let a = 0;a < this.goodsData.length;a++){
						for(let b = 0;b < this.goodsData[a].spec.length;b++){
							// console.log(this.goodsData[a].spec[b])
							if(this.goodsData[a].spec[b] == undefined){
								type = false;
								let array = [];
								array.push(this.goodsData[a]);
								this.expandChanges(this.goodsData[a],array)
							}
						}
					}
					if(type){
						if (this.title == '新增') {
							return this.formSubmitAdd(form, done);
						}
						if (this.title == '修改') {
							return this.formSubmitUpdate(form, done);
						}
					}else{
						done();
						this.$message.error('请选择完整规格!')
					}
				} else {
					done();
					this.$message.error('请填写商品数量');
				}
			}
		},
		// 新增
		formSubmitAdd(form, done) {
			schemeAdd({
				name: form.name,
				mainGraph: form.mainGraph,
				sort: form.sort,
				state: form.state,
				detail: this.goodsData,
				details: this.uText
			})
				.then(res => {
					// console.log(res);
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.$root.eventHub.$emit('changeSchemeList', true);
						done();
					} else {
						this.$message.error(res.msg);
						done();
					}
				})
				.catch(err => {
					this.$message.error(err.msg);
					done();
				});
		},
		// 修改
		formSubmitUpdate(form, done) {
			schemeUpdate({
				id: form.id,
				name: form.name,
				mainGraph: form.mainGraph,
				sort: form.sort,
				state: form.state,
				detail: this.goodsData,
				details: this.uText
			})
				.then(res => {
					// console.log(res)
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.$root.eventHub.$emit('changeSchemeList', true);
						done();
					} else {
						this.$message.error(res.msg);
						done();
					}
				})
				.catch(err => {
					this.$message.error(err.msg);
					done();
				});
		},
		clickAdd() {
			// console.log('新增');
			this.dialogVisible = true;
		},
		clickCel(i) {
			this.goodsData.splice(i, 1);
		},
		goBack() {
			this.$emit('goBack', 'goBack');
		},
		clickChoice(e) {
			productView(e).then(res => {
				if (res.code === 200) {
					// console.log(res);
					this.dialogVisible = false;
					res.data.spec.forEach(item => {
						item.value.forEach(itemA => {
							itemA.type = false;
						});
					});
					let array = new Array(res.data.spec.length);
					let data = {
						num: 1,
						productId: res.data.id,
						spec: array,
						specList: res.data.spec,
						mainGraph: res.data.mainGraph,
						name: res.data.name
					};
					this.goodsData.push(data);
					setTimeout(()=>{
						let newArray = [];
						newArray.push(this.goodsData[this.goodsData.length - 1]);
						this.expandChanges(this.goodsData[this.goodsData.length - 1],newArray)
					},1000)
					// console.log(this.goodsData);
				}
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error('上传失败');
			// console.log(error, column);
		},
		uploadAfter(res, done) {
			done();
			// this.$message.success('上传后的方法')'
			this.$nextTick(() => {
				this.form.mainGraph = res.path;
			});
		},
		expandChanges(row, expendList) {
			if (expendList.length) {
				this.goodsOption.expandRowKeys = [];
				if (row) {
					this.goodsOption.expandRowKeys.push(row.$index);
				}
			} else {
				this.goodsOption.expandRowKeys = [];
			}
		}
	}
};
</script>

<style scoped>
.avue-crud >>> .el-table__cell .cell input {
	width: 80%;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	padding: 0 10px;
}
.el-cascader >>> input {
	width: 100% !important;
}
</style>
