<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { officeAdd,officeUpdate } from '@/api/person/office';
    export default {
        name: "office",
        props: {
            title: String,
            dialogVisible: Boolean,
            officeViewForm: Object
        },
        data(){
            return{
                form: this.officeViewForm,
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label: '名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入名称",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '经销商',
                            prop: 'dealer',
                            rules: [{
                                required: true,
                                message: "请输入经销商",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '大区',
                            prop: 'regionId',
                            type: 'select',
                            dicData: this.$store.state.office.regionList,
                            props: {
                                label: "name",
                                value: 'id'
                            },
                            rules: [{
                                required: true,
                                message: "请选择大区",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        }
                    ]
                }
            }
        },
        methods: {
            formSubmit(form,done){
                // console.log(form)
                if(this.title == '新增'){
                    return this.formSubmitRoleAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitRoleUpdate(form,done)
                }
            },
            // 新增
            formSubmitRoleAdd(form,done){
                officeAdd({
                    name: form.name,
                    dealer: form.dealer,
                    regionId: form.regionId
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeOffice', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitRoleUpdate(form,done){
                officeUpdate({
                    id: form.id,
                    name: form.name,
                    dealer: form.dealer,
                    regionId: form.regionId
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeOffice', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeOffice', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>