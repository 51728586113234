<template>
    <div class="specs-table">
        <div class="specs-item" v-for="(item,index) in specsList" :key="index">
            <div class="specs-itemA">
                <div class="item-title">规格名称: </div>
                <div class="item-input">
                    <input :disabled="title == '查看' ? true : false" type="text" v-model="item.key" @input="input('parent')" placeholder="请输入名称" @blur="specsBlur">
                </div>
                <i class="error1 el-icon-error" v-if="title == '查看' ? false : true" @click="clickClose(index)"></i>
            </div>
            <div class="specs-itemB">
                <div class="item-title">规格值: </div>
                <div class="item-input" v-for="(itemC,indexC) in item.value" :key="indexC">
                    <input :disabled="title == '查看' ? true : false" type="text" v-model="itemC.content" @input="input('children',item.value.length,indexC,index)" placeholder="请输入名称" @blur="specsBlur">
                    <i class="error2 el-icon-error" v-if="(title == '查看' ? false : true ) && item.value.length == 1 ? false : true" @click.stop="changeSpecs(itemC,index,indexC,item.value.length)"></i>
                </div>
                <div class="item-add" v-if="title == '查看' ? false : true" @click="clickAddChildren(index,'clickAddChildren',item.value.length)">添加</div>
            </div>
        </div>
        <div class="specs-itemA" v-if="title == '查看' ? false : true">
            <el-button type="primary" icon="el-icon-plus" size="small" @click="clickAddParent()">添加规格</el-button>
            <el-button type="primary" icon="el-icon-s-help" size="small" @click="synchronousStock(syncStock)">同步规格</el-button>
        </div>

		<avue-crud :data="data" :option="option" v-if="specsList.length > 0">
			<template slot="model" slot-scope="{ row }">
				<input
					type="text"
					v-model="row.model"
					@input="priceInput"
					:disabled="title == '查看' ? true : false"/>
			</template>
			<template slot="quality" slot-scope="{ row }">
				<input
					type="text"
					v-model="row.quality"
					@input="priceInput"
					:disabled="title == '查看' ? true : false"/>
			</template>
			<template slot="image" slot-scope="{ row,index }">
				<div class="upload">
					<input class="uploadImage" type="file" @change="tirggerFile($event,index)"/>
					<img v-if="row.image" :src="row.image" alt="" />
					<i v-else>添加</i>
				</div>
			</template>
			<template slot="price" slot-scope="{ row }">
				<input
					type="number"
					v-model="row.price"
					@input="priceInput"
					:disabled="title == '查看' ? true : false"
				/>
			</template>

			<template slot="stock" slot-scope="{ row }">
				<input
					type="number"
					v-model="row.stock"
					min="1"
					pattern="[0-9]"
					@input="priceInput"
					:disabled="title == '查看' ? true : false"
					onkeyup="value=value.replace(/[\W]/g,'')"
					onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
				/>
			</template>

		</avue-crud>
    </div>
</template>

<script>
    import { actionUrl,baseUrl } from '@/config/public';
	import axios from 'axios';
    export default {
        name: "specs",
        props: {
            specsData: Array,
            title: String,
			defaultSpecList: Array,
			syncStock: String/Number
        },
		data(){
			return{
				specsList: this.specsData,
				data: [],
				dataType: [],
				option: {
					addBut: false,
					editBut: false,
					celBut: false,
					header: false,
					menu: false,
					border: true,
					align: 'center',
					column: []
				},
				column: [
					{
						label: '型号',
						prop: 'model',
						slot: true
					},
					{
						label: '品号',
						prop: 'quality',
						slot: true
					},
					{
						label: '规格图片',
						prop: 'image',
						slot: true
					},
					{
						label: '价格',
						prop: 'price',
						slot: true
					},
					{
						label: '库存',
						prop: 'stock',
						slot: true
					}
				],
				setStorageArray: []
			}
		},
		mounted() {
			if(this.specsList.length > 0  && this.title != '新增'){
				this.inputParent();
				// console.log('defaultSpecList',this.defaultSpecList)
				if(this.defaultSpecList == undefined || this.defaultSpecList.length == 0){
					this.AddHeader();
					// 多规格数组缓存
				}
				this.setStorageArray = this.defaultSpecList;
			}

		},
        methods:{
			AddHeader(type,typeLength,typeIndex,typeLabelIndex){
				// console.log(this.specsList)

				if(this.specsList.length == 1){
					let result = [];
					for(var a = 0;a < this.specsList.length;a++){
						for(var b = 0;b < this.specsList[a].value.length;b++){
							result.push({
								name: this.specsList[a].value[b].content,
								spec0: this.specsList[a].value[b].content,
								model: '',
								quality: '',
								image: '',
								price: '',
								stock: ''
							})
						}
					}

					console.log(result)
					this.$nextTick(()=>{
						this.data = result;
					})
				}else if(this.specsList.length > 1){
					// console.log(222222222222)
					/*方式二：多个数组求笛卡尔积*/

					// 作为二维数组来运算
					var newArray = [];
					for(let item of this.specsList){
						newArray.push(item.value)
					}
					// last为上次运算的结果，current为数组中的当前元素
					var result = newArray.reduce((last, current) => {
						const array = [];
						last.forEach(par1 => {
							current.forEach(par2 => {
								array.push({name:(par1.content || par1.name) + "," + par2.content});
							});
						});
						return array;
					});
					for(var i =0;i < result.length;i++){
						let resultArray = result[i].name.split(',');
						for(var a = 0;a < this.specsList.length;a++){
							let label = `spec${a}`;
							result[i][label] = resultArray[a];
							result[i].model = '';
							result[i].quality = '';
							result[i].image = '';
							result[i].price = '';
							result[i].stock = '';
						}
					}
					console.log(result)
					this.data = result;
					// this.$root.eventHub.$emit('changeSpecification', this.data)
				}
				this.testing(this.data);
			},
			testing(data){
				data.forEach((itemA,indexA) => {
					this.setStorageArray.forEach((itemB,indexB) =>{
						if(itemA.name == itemB.name){
							// console.log('itemA',itemA.name,indexA)
							// console.log('itemB',itemB.name,indexB)
							this.data[indexA] = itemB
						}
					})
				})
				this.$root.eventHub.$emit('changeSpecification', this.data)
			},
			inputParent(){
				this.option.column = [];
				let columnArray = []
				for(var i = 0;i < this.specsList.length;i++){
					let label = `spec${i}`
					columnArray.push({label: this.specsList[i].key, prop: label,slot: true})
					if(i == this.specsList.length - 1){
						columnArray.push({
							label: '型号',
							prop: 'model',
							slot: true
						},
						{
							label: '品号',
							prop: 'quality',
							slot: true
						},
						{
							label: '规格图片',
							prop: 'image',
							slot: true
						},
						{
							label: '价格',
							prop: 'price',
							slot: true
						},
						{
							label: '库存',
							prop: 'stock',
							slot: true
						})
					}
				}
				// console.log(columnArray)
				this.$nextTick(()=>{
					this.option.column = columnArray;
					if(this.defaultSpecList.length > 0){
						for(var i =0;i < this.defaultSpecList.length;i++){
							let resultArray = this.defaultSpecList[i].name.split(',');
							for(var a = 0;a < this.specsList.length;a++){
								let label = `spec${a}`;
								this.defaultSpecList[i][label] = resultArray[a];
							}
							this.data = this.defaultSpecList;
							this.dataType = this.defaultSpecList;
							// console.log(111,this.data)
						}

					}
					// console.log(this.option.column)
				})
			},
			input(name,length,index,i){
				if(name === 'parent'){
					this.inputParent();
				}else{
					this.AddHeader();
				}
			},
			priceInput(){
				this.setStorageArray = this.data;
				this.$root.eventHub.$emit('changeSpecification', this.data)
				// console.log(this.data)
			},
            changeSpecs(e,parent,children,length){
                this.specsList[parent].value.splice(children,1)
				// console.log(length,children)
				this.AddHeader();
                this.$root.eventHub.$emit('changeSpecs', this.specsList)
            },
            clickAddParent(){
                this.specsList.push({
                    key: '',
                    value: [{content: ''}]
                })
				this.inputParent();
				this.AddHeader();
                this.$root.eventHub.$emit('changeSpecs', this.specsList)
            },
            clickAddChildren(i,name,length){
                this.specsList[i].value.push({
                    content: ''
                })
				this.AddHeader();
                this.$root.eventHub.$emit('changeSpecs', this.specsList)
            },
            clickClose(i){
                this.specsList.splice(i,1)
				this.inputParent();
				this.AddHeader();
                this.$root.eventHub.$emit('changeSpecs', this.specsList)
            },
            specsBlur(){
                // console.log(this.specsList)
                this.$root.eventHub.$emit('changeSpecs', this.specsList)
            },
			tirggerFile(event,index){
				let self = this;
				let file = event.target.files[0]
				let param = new FormData() // 创建form对象
				param.append('file', file, file.name) // 通过append向form对象添加数据
				param.append('type', '1') // 添加form表单中其他数据
				let config = {
				  headers: {'Content-Type': 'multipart/form-data'}
				}
				axios.post('/web/tool/file/upload', param, config)
				  .then(response => {
					// console.log(response)
					if(response.data.code === 200){
						this.$nextTick(()=>{
							this.data[index].image = response.data.data.image;
							this.setStorageArray = this.data;
						})
						// console.log(this.data)
						this.$root.eventHub.$emit('changeSpecification', this.data)
						this.$message.success('上传成功')
					}else{
						this.$message.error(response.data.msg)
					}
				  })
			},
			synchronousStock(syncStock){
				console.log('synchronousStock',syncStock)
				let self = this;
				self.$nextTick(()=> {
					self.data.forEach(item =>{
						item.stock = syncStock
					})
				})
			}
        }
    }
</script>

<style scoped>
.specs-table{
    padding: 10px;
    border: 1px solid #e6e6e6;
    margin-bottom: 40px;
}
    .specs-item{

    }
    .specs-itemA{
        height: 50px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        position: relative;
    }
    .specs-itemB{
        padding: 0 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }
    .item-title{
        font-size: 13px;
        color: #333333;
        line-height: 50px;
        padding-right: 20px;
    }
    .item-input{
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 20px;
    }
    .item-input input{
        width: 120px;
        height: 30px;
        padding: 0 15px;
        font-size: 12px;
        color: #6b6b6b;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .error1{
        font-size: 20px;
        color: #409eff;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0,-50%);
        cursor: pointer;
    }
    .error2{
        font-size: 16px;
        color: #409eff;
        position: absolute;
        top: 5px;
        right: -5px;
        cursor: pointer;
    }
    .item-add{
        font-size: 14px;
        color: #409eff;
        line-height: 50px;
        cursor: pointer;
    }

	.avue-crud >>> .el-table__cell .cell input {
		width: 80%;
		height: 30px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
		padding: 0 10px;
	}
	.upload{
		width: 32px;
		height: 32px;
		position: relative;
		margin: 0 auto;
	}
	.uploadImage{
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	.upload img{
		display: block;
		width: 32px;
		height: 32px;
	}
	.upload i{
		display: block;
		width: 32px;
		height: 32px;
		font-size: 14px;
		color: #333333;
		line-height: 32px;
		text-align: center;
		cursor: pointer;
	}
</style>
