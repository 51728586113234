<template>
    <el-dialog
            :visible.sync="moduleType"
            width="60%"
            :before-close="handleClose">

        <avue-crud :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @search-change="searchChange"
                   @search-reset="searchReset"
                   @on-load="onLoad">
            <template slot="menu" slot-scope="{row}">
                <el-button
                        style="margin-left:10px;"
                        type="text"
                        size="small"
                @click="choice(row)">选择</el-button>
            </template>
        </avue-crud>

    </el-dialog>
</template>

<script>
    import { paramList,paramView } from '@/api/product/param';
    export default {
        name: "module",
        props: {
            moduleType: Boolean
        },
        data() {
            return{
                search: {},
                data: [],
                option:{
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    addBtn:false,
                    editBtn:false,
                    delBtn:false,
                    align: 'center',
                    header: false,
                    searchMenuSpan: 6,
                    searchIndex:1,
                    searchIcon:true,
                    searchShowBtn:false,
                    menuWidth: 150,
                    column:[
                        {
                            label: '名称',
                            prop: 'name',
                            search: true,
                            searchSpan: 12,
                            rules: [{
                                message: "请输入分类名称",
                                trigger: "blur"
                            }]
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        methods: {
            onLoad(){
                this.GetParamList();
            },
            GetParamList() {
                paramList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                });
            },
            searchChange(params,done){
                // console.log(params.name)/
                this.search = params
                this.GetParamList();
                done();
            },
            searchReset(params){
                // console.log(params)/
                this.search.name = '';
                this.GetParamList();
            },
            choice(row){
                // console.log(row)
                paramView(row.id).then(res => {
                    console.log(res)
                    if(res.code === 200){
                        this.$root.eventHub.$emit('changeModule', {data: res.data,type: true})
                    }
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeModule', {data: res.data,type: false})
                    })
            }
        }
    }
</script>

<style scoped>

</style>