import { get,post,put,Delete } from '../axios'

// 物业项目-列表
export const projectList = params => get('/web/person/property/project/list',params);

// 物业项目-新增
export const projectAdd = params => post('/web/person/property/project/add',params);

// 物业项目-查看
export const projectView = params => get(`/web/person/property/project/view/${params}`);

// 物业项目-修改
export const projectUpdate = params => put('/web/person/property/project/update',params);

// 物业项目-删除
export const projectDelete = params => Delete(`/web/person/property/project/delete/${params}`);

// 物业项目-邀请码-列表
export const projectCodeList = params => get('/web/person/property/project/code/list',params);

// 物业项目-邀请码-新增
export const projectCodeAdd = params => post('/web/person/property/project/code/add',params);

// 物业项目-邀请码-查看
export const projectCodeView = params => get(`/web/person/property/project/code/view/${params}`);

// 物业项目-邀请码-修改
export const projectCodeUpdate = params => put('/web/person/property/project/code/update',params);

// 物业项目-邀请码-删除
export const projectCodeDelete = params => Delete(`/web/person/property/project/code/delete/${params}`);

// 物业项目下-邀请码下-用户-列表
export const projectCodeUserList = params => get('/web/person/property/project/code/user/list',params);

