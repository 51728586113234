<template>
    <div class="main-content">

        <div class="table-content">
            <avue-crud :data="data"
                       :option="option"
                       :search.sync="search"
                       :page.sync="pageSync"
                       @on-load="onLoad"
                       @search-change="searchChange"
                       @search-reset="searchReset"
                       ref="crud"
                       @selection-change="selectionChange">
                <template slot="menuLeft">
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                               :icon="item.icon"
                               size="small"
                               :plain="item.name == '新增' ? false : true"
                               v-show="(item.name != '修改') && (item.name != '查看')"
                               @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
                </template>

                <template slot="menu" slot-scope="{row}">
                    <el-button
                            style="margin-left:10px;"
                            size="small"
                            type="text"
                            icon="el-icon-upload2"
                            @click.stop="clickMenu('上架',row)">上架</el-button>
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                               style="margin-left:10px;"
                               size="small"
                               type="text"
                               :icon="item.icon"
                               @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
                </template>
            </avue-crud>
        </div>

        <roleIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :choiceId="choiceId" :title="title"/>

        <rolePower v-if="dialogVisiblePower" :dialogVisible="dialogVisiblePower" :choiceId="ids[0]" :tabs="tabs"/>
    </div>
</template>

<script>
    import { roleList,roleDelete,roleMenuTree } from "@/api/system/role";
    import roleIndex from '@/components/system/role/index';
    import rolePower from '@/components/system/role/power';
    export default {
        name: "list",
        components:{
            roleIndex,
            rolePower
        },
        data() {
            return {
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                Buttons: [],
                search:{},
                data: [],
                option:{
                    border:true,
                    stripe:false,
                    showHeader:true,
                    index:true,
                    selection:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    searchMenuSpan: 6,
                    menuWidth: 300,
                    column:[
                        {
                            label:'角色名称',
                            prop:'name',
                            search:true
                        },
                        {
                            label:'备注',
                            prop:'remarks'
                        },
                        {
                            label:'创建时间',
                            prop:'createTime'
                        }
                    ]
                },

                title: '',
                choiceId: '',
                dialogVisible: false,
                dialogVisiblePower: false,
                ids: [],
                name: '', // 请求false

                // 角色权限管理
                tabs: []
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons

            this.$root.eventHub.$on('changeRole', (n)=>{
                this.dialogVisible = false;
                if(n){
                    this.role_list();
                }
            })

            this.$root.eventHub.$on('changeRolePower', (n)=>{
                this.dialogVisiblePower = n;
            })
        },
        methods:{
            searchChange(params,done) {
                this.name = params.name;
                this.role_list();
                done();
            },
            searchReset(params){
                this.name = params.name;
                this.role_list();
            },
            onLoad(){
                this.role_list()
            },
            role_list(){
                roleList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.name
                }).then(res => {
                    this.data = res.data.records;
                    this.pageSync.total = res.data.total;
                    this.pageSync.currentPage = res.data.current;
                    if(res.data.current > res.data.pages){
                        this.pageSync.currentPage = res.data.pages;
                        this.role_list();
                    }
                })
            },
            clickMenu(name,form){
                if(name == '删除'){
                    this.rowDel(form);
                }else if(name == '权限设置'){
                    this.roleTreeMenu();
                }else{
                    this.title = name;
                    // console.log('title',name)
                    this.dialogVisible = true;
                    if(form != undefined){
                        this.choiceId = form.id;
                    }
                }
            },
            selectionChange(list){
                let array = [];
                if(list.length == 0){
                    this.ids = [];
                }else{
                    list.forEach(item => {
                        array.push(item.id);
                    })
                    this.ids = array;
                }
            },
            rowDel(form){
                if(form == undefined){
                    if(this.ids.length > 0){
                        this.UserDelete()
                    }else{
                        this.$message({
                            showClose: true,
                            message: '请选择一项数据',
                            type: 'warning'
                        });
                    }
                }else{
                    this.UserDelete(form.id)

                }
            },
            roleTreeMenu(){
                if(this.ids.length === 1){
                    roleMenuTree().then(res => {
                        if(res.code === 200){
                            this.tabs = res.data;
                            this.dialogVisiblePower = true;
                        }else{
                            this.$message.error(res.msg);
                        }
                    }).catch(err => {
                        this.$message.error(err.msg);
                    })
                }else{
                    this.$message({
                        showClose: true,
                        message: '请选择一项数据',
                        type: 'warning'
                    });
                }
            },
            UserDelete(id){
                this.$confirm(`删除${id == undefined ? this.ids.length : '这'}条数据`, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        if(action == 'confirm'){
                            roleDelete( id == undefined ? this.ids : id ).then(res => {
                                // 获取数据成功后的其他操作
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.role_list();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>