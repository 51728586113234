<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { menuAdd,menuView,menuUpdate,menuTree } from '@/api/system/menu';
    import iconList from "@/config/iconList";
    export default {
        name: "add",
        props: {
            title: String,
            choiceId: String,
                parentId: String,
            dialogVisible: Boolean,
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label:'菜单名称',
                            prop:'name',
                            span: 12,
                            rules: [{
                                required: true,
                                message: "请输入菜单名称",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label:'菜单图标',
                            prop:'icon',
                            type: 'icon',
                            span: 12,
                            iconList: iconList,
                            rules: [
                                {
                                    required: true,
                                    message: "请输入菜单图标",
                                    trigger: "click"
                                }
                            ],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label:'路由地址',
                            prop:'url',
                            span: 12,
                            rules: [{
                                required: true,
                                message: "请输入路由地址",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: "菜单排序",
                            prop: "sort",
                            type: "number",
                            slot: true,
                            rules: [{
                                required: true,
                                message: "请输入菜单排序",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '上级菜单',
                            prop: 'parentId',
                            filter:false,
                            type: 'tree',
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                            disabled: this.title == '新增' ? false : this.parentId === '0' && this.choiceId != '0' && this.title != '查看' ? false : true
                        },
                        {
                            label: "类型",
                            prop: "type",
                            span: 12,
                            type: "radio",
                            dicData: [{
                                label: '菜单',
                                value: 1
                            }, {
                                label: '按钮',
                                value: 2
                            }],
                            rules: [{
                                required: true,
                                message: "请选择类型",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        }
                    ]
                },
            }
        },
        mounted() {
            // console.log(this.parentId)
            // console.log(this.choiceId)
            // console.log(this.title)
            // console.log(this.parentId === '0' && this.choiceId == '0' && this.title != '查看' ? false : true)


            if(this.title == '查看' || this.title == '修改'){
                menuView(this.choiceId).then(res =>{
                    // console.log(res)
                    if(res.code === 200){
                        this.form = res.data;
                    }
                })
            }
            menuTree().then(res => {
                if(res.code === 200){
                    const column = this.findObject(this.option.column, "parentId");
                    column.dicData = res.data;
                    this.form.parentId = this.choiceId;
                }
            });
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    this.formSubmitA(form,done)
                }else if (this.title == '修改'){
                    this.formSubmitB(form,done)
                }
            },
            formSubmitA(form,done){
                // console.log(form)
                menuAdd({
                    parentId: this.form.parentId == '' ? 0 : this.form.parentId,
                    icon: this.form.icon,
                    name: this.form.name,
                    perms: 'system',
                    sort: this.form.sort,
                    type: this.form.type,
                    url: this.form.url,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success('添加成功')
                        this.$root.eventHub.$emit('changeMenu', true)
                        done();
                    }else{
                        this.$message.error(res.msg)
                        done();
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done();
                })
            },
            formSubmitB(form,done){
                menuUpdate({
                    id: this.choiceId,
                    parentId: this.form.parentId == '' ? 0 : this.form.parentId,
                    icon: this.form.icon,
                    name: this.form.name,
                    perms: 'system',
                    sort: this.form.sort,
                    type: this.form.type,
                    url: this.form.url,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success('修改成功')
                        this.$root.eventHub.$emit('changeMenu', true)
                        done();
                    }else{
                        this.$message.error(res.msg)
                        done();
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done();
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeMenu', false)
                    })
            },
        }
    }
</script>

<style scoped>

</style>