import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';



// view 内容
// index首页
import indexHome from '@/view/index/index';
// info  个人信息
import infoIndex from '@/view/info/index';

// 配置订单
import orderList from '@/view/configure/order/list';

// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';
import systemMenu from '@/view/system/menu';
import systemLog from '@/view/system/log';
import systemNotice from '@/view/system/notice';
import systemContact from '@/view/system/contact';
import systemAdvert from '@/view/system/advert';
import systemIntroduce from '@/view/system/introduce';
import systemEmail from '@/view/system/email';

// product 商品管理
import productList from '@/view/product/list/list';
import classify from '@/view/product/classify/classify';
import series from '@/view/product/classify/series';
import homeClassify from '@/view/product/home/classify';
import param from '@/view/product/param/param';
import schemeIndex from '@/view/product/scheme/index';

// person 人员管理
import region from '@/view/person/region';
import office from '@/view/person/office/office';
import code from '@/view/person/office/code';
import officeApply from '@/view/person/office/apply';
import project from '@/view/person/property/index';
import projectApply from '@/view/person/property/apply';
import personUser from '@/view/person/user';

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [{
				path: '/',
				name: 'indexHome',
				component: indexHome
			}, {
				path: '/info/index',
				name: 'infoIndex',
				component: infoIndex
			}, {
				path: '/system/user',
				name: '/systemUser',
				component: systemUser
			},
				{
					path: '/system/role',
					name: '/role',
					component: systemRole
				},
				{
					path: '/system/menu',
					name: '/menu',
					component: systemMenu
				},
				{
					path: '/system/contact',
					name: '/contact',
					component: systemContact
				},
				{
					path: '/system/log',
					name: '/log',
					component: systemLog
				},
				{
					path: '/system/notice',
					name: '/notice',
					component: systemNotice
				},
				{
					path: '/system/advert',
					name: '/advert',
					component: systemAdvert
				},
				{
					path: '/system/introduce',
					name: '/introduce',
					component: systemIntroduce
				},
				{
					path: '/system/email',
					name: '/systemEmail',
					component: systemEmail
				},
				//------------------------------------------------------------
				{
					path: '/configure/order/list',
					name: '/orderList',
					component: orderList
				},
				//----------------------------------------------------------
				{
					path: '/product/list',
					name: '/list',
					component: productList
				},
				{
					path: '/product/param',
					name: '/param',
					component: param
				},
				{
					path: '/product/classify',
					name: '/classify',
					component: classify
				},
				{
					path: '/product/classify/series',
					name: '/series',
					component: series
				},
				{
					path: '/product/home/classify',
					name: '/homeClassify',
					component: homeClassify
				},
				{
					path: '/product/scheme',
					name: '/scheme',
					component: schemeIndex
				},
				//------------------------------------------------------------
				{
					path: '/person/region',
					name: '/region',
					component: region
				},
				{
					path: '/person/office',
					name: '/office',
					component: office
				},
				{
					path: '/person/office/code',
					name: '/code',
					component: code
				},
				{
					path: '/person/office/apply',
					name: '/officeApply',
					component: officeApply
				},
				{
					path: '/person/property/project',
					name: '/project',
					component: project
				},
				{
					path: '/person/property/apply',
					name: '/projectApply',
					component: projectApply
				},
				{
					path: '/person/user',
					name: '/personUser',
					component: personUser
				}
			]
		}
	]
})
