var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-page-header',{attrs:{"content":_vm.title},on:{"back":_vm.goBack}}),_c('el-divider'),_c('avue-form',{attrs:{"option":_vm.option,"upload-error":_vm.uploadError,"upload-delete":_vm.uploadDelete,"upload-after":_vm.uploadAfter},on:{"submit":_vm.formSubmit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"goods"},[_c('avue-crud',{attrs:{"data":_vm.goodsData,"option":_vm.goodsOption},on:{"expand-change":_vm.expandChanges},scopedSlots:_vm._u([{key:"num",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.num),expression:"row.num"}],attrs:{"type":"number","min":"1","pattern":"[0-9]","disabled":_vm.title == '查看' ? true : false,"onkeyup":"value=value.replace(/[\\W]/g,'')","onbeforepaste":"clipboardData.setData('text',clipboardData.getData('text').replace(/[^\\d]/g,''))"},domProps:{"value":(row.num)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "num", $event.target.value)}}})]}},{key:"expand",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('SchemeSpec',{attrs:{"specList":row.specList,"spec":row.spec,"index":index}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-circle-close"},on:{"click":function($event){$event.stopPropagation();return _vm.clickCel(index)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"menuLeft"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.clickAdd.apply(null, arguments)}}},[_vm._v("新增")])],1)],2)],1),_c('template',{slot:"details"},[_c('avue-ueditor',{attrs:{"options":_vm.uOptions},model:{value:(_vm.uText),callback:function ($$v) {_vm.uText=$$v},expression:"uText"}})],1)],2),(_vm.dialogVisible)?_c('SchemeIndex',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"clickChoice":_vm.clickChoice}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }