<template>
    <el-dialog
            title="编辑"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-before="uploadBefore"
                   :upload-after="uploadAfter"></avue-form>
        <span slot="footer" class="dialog-footer">
            <el-button icon="el-icon-circle-check" type="primary" size="small" @click="handleSubmit">修 改</el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import { imageUrl,actionUrl } from '@/config/public';
    import { userView,userUpdate } from "@/api/system/index";
    export default {
        name: "edit",
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            choiceId: String
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '头像',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 12,
                            row: true,
                            prop: 'headPortrait',
                            loadText: '附件上传中，请稍等',
                            propsHttp:{
                                res:'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl
                        },
                        {
                            label:'用户姓名',
                            prop:'fullName',
                            rules: [{
                                required: true,
                                message: "请输入用户姓名",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'手机号码',
                            prop:'phone',
                            rules: [{
                                required: true,
                                message: "请输入手机号码",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "所属角色",
                            prop: "role",
                            type: "select",
                            dicData: this.$store.state.system.role,
                            span: 24,
                            multiple:true,
                            props: {
                                label: 'name',
                                value: 'id'
                            },
                        }
                    ]
                }
            }
        },
        mounted() {
            userView(this.choiceId).then(res => {
                this.form = res.data;
            })
        },
        methods:{
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                //如果你想修改file文件,由于上传的file是只读文件，必须复制新的file才可以修改名字，完后赋值到done函数里,如果不修改的话直接写done()即可
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile)

                // this.$message.success('上传前的方法')
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            },
            uploadAfter(res, done) {
                done()
                // this.$message.success('上传后的方法')'
                // console.log(res)
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.form.image = res.image;
                    this.form.headPortrait = res.path;
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeEdit', false);
                    })
            },
            handleSubmit(){
                userUpdate({
                    fullName: this.form.fullName,
                    headPortrait: this.form.headPortrait,
                    id: this.form.id,
                    phone: this.form.phone,
					roleIds: this.form.role.toString()
                }).then(res => {
                    // 获取数据成功后的其他操作
                    // console.log(res.data)
                    if(res.code === 200){
                        this.$message.success('修改成功')
                    }else{
                        this.$message.error(res.msg)
                    }
                    this.$root.eventHub.$emit('changeEdit', false)
                })
            }
        }
    }
</script>

<style scoped>
</style>