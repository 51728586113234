import {get, post, put,Delete} from '../axios'
/* 公告管理-列表 */
export const introduceList = params => get('/web/system/introduce/list',params);

// 公告管理-查看
export const introduceView = params => get(`/web/system/introduce/view/${params}`);

// 公告管理-修改
export const introduceUpdate = params => put('/web/system/introduce/update',params);

// 公告管理-新增
export const introduceAdd = params => post('/web/system/introduce/add',params);

// 公告管理-删除
export const introduceDelete = params => Delete(`/web/system/introduce/delete/${params}`);
