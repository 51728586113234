const product = {
    state: {
        classifyHome: null,
        productTree: null,
        classifyTree: null,
    },
    mutations: {
        SetClassifyHome(state,info){
            state.classifyHome = info
        },
        SetProductTree(state,info){
            state.productTree = info
        },
        SetClassifyTree(state,info){
            state.classifyTree = info
        }
    }

}
export default product