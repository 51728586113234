<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { officeCodeAdd,officeCodeView,officeCodeUpdate } from '@/api/person/code';
    export default {
        name: "Code",
        props: {
            title: String,
            id: {
                default: '0',
                type: String
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label: '类型',
                            prop: 'type',
                            type: 'select',
                            dicData: [
                                {
                                    label: '办事处',
                                    value: 1
                                },
                                {
                                    label: '物业',
                                    value: 2
                                }
                            ],
                            rules: [{
                                required: true,
                                message: "请输入类型",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '邀请码',
                            prop: 'code',
                            rules: [{
                                required: true,
                                message: "请输入邀请码",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        }
                    ]
                }
            }
        },
        mounted() {
            let that = this;
            if(this.id != 0){
                that.GetOfficeCodeView();
            }

        },
        methods: {
            GetOfficeCodeView(){
                officeCodeView(this.id).then(res => {
                    // console.log(res)
                    this.form = res.data;
                })
            },
            formSubmit(form,done){
                // console.log(form)
                if(this.title == '新增'){
                    return this.formSubmitRoleAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitRoleUpdate(form,done)
                }
            },
            // 新增
            formSubmitRoleAdd(form,done){
                officeCodeAdd({
                    type: form.type,
                    code: form.code
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeCode', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitRoleUpdate(form,done){
                officeCodeUpdate({
                    id: this.id,
                    type: form.type,
                    code: form.code
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeCode', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeCode', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>
