<template>
    <el-dialog
            title="选择商品"
            :visible.sync="frameDatatype"
            width="60%"
            :before-close="handleClose">
			
		<avue-crud :data="goodsData"
		           :option="goodsOption"
		           :page.sync="pageSync"
		           :table-loading="loading"
		           @on-load="onLoad">
		
		    <template slot-scope="{row}" slot="menu">
		        <div style="cursor: pointer" @click.once="clickChoice(row)">选择</div>
		    </template>
		</avue-crud>
    </el-dialog>
</template>

<script>
    import { imageUrl } from '@/config/public';
    import { productList } from "@/api/product/list";
    export default {
        props: {
            frameDatatype: Boolean
        },
        data(){
            return{
                loading: true,
                goodsData: [],
                goodsOption:{
                    header: false,
                    showHeader: false,
                    border: true,
                    align:'center',
                    menuAlign:'center',
                    addBtn: false,
                    editBtn: false,
                    delBtn:false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新,
                    menuWidth: 100,
                    column:[
                        {
                            label: '主图',
                            prop: 'mainGraph',
                            type: 'upload',
                            listType: 'picture-img',
                            width: 100,
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label:'名称',
                            prop:'name',
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                typeId: ''
            }
        },
        methods: {
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('clickChoiceframeId', {type: false});
                    })
            },
            onLoad(){
                this.list();
            },
            list(){
                productList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then(res => {
                    if(res.code === 200){
                        this.$nextTick(()=>{
                            this.loading = false;
                            this.goodsData = res.data.records;
                            this.pageSync.currentPage = res.data.current;
                            this.pageSync.total = res.data.total;
                        })
                    }
                })
            },
            clickChoice(form){
				// console.log(form)
				this.$root.eventHub.$emit('clickChoiceframeId', {type: true,frameId:form.id,frameProductName:form.name});
            }
        }
    }
</script>

<style>
	.isTian_mask{
		width: 500px;
		height: 500px;
		background-color: #FFFFFF;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 999999;
		transform: translate(-50%,-50%);
		padding: 50px;
	}
</style>
