<template>
	<div>
		<el-steps :active="active" finish-status="success" simple style="margin-bottom: 20px">
			<el-step title="1.基础信息"></el-step>
			<el-step title="2.规格/价格"></el-step>
			<el-step title="3.商品详情"></el-step>
			<el-step title="4.商品详情"></el-step>
		</el-steps>
		<avue-form ref="form" :option="option" v-model="form" :upload-error="uploadError" :upload-delete="uploadDelete" :upload-after="uploadAfter" @submit="formSubmit">
			<template slot="frameId">
				<div>{{ form.frameProductName }}</div>
				<el-button size="small" type="primary" @click="clickFrameId()">选择</el-button>
			</template>

			<template slot="paramButton">
<!--				<el-button size="small" v-for="(item, index) in paramButtons" :key="index" @click="paramButton(item)">{{ item.name }}</el-button>-->
				<div style="display: flex">
					<el-button size="small" @click="paramButtonModule">选择模板</el-button>
					<div v-if="paramName != ''" style="color: #409EFF;margin-left: 20px">已选：{{paramName}}</div>
				</div>
			</template>

			<template slot="details">
				<avue-crud :data="paramData" :option="option1" @sortable-change="sortableChange">
					<template slot="key" slot-scope="{ row }">
						<input type="text" v-model="row.key" :disabled="title == '查看' ? true : false" />
					</template>
					<template slot="value" slot-scope="{ row }">
						<input type="text" v-model="row.value" :disabled="title == '查看' ? true : false" />
					</template>
					<template slot-scope="{ row, index }" slot="menu">
						<el-button type="primary" icon="el-icon-circle-plus-outline" v-if="index == 0" @click.stop="clickAdd">添加</el-button>
						<el-button type="danger" icon="el-icon-circle-close" v-if="index != 0" @click.stop="clickCel(index)">删除</el-button>
					</template>
				</avue-crud>
			</template>
		</avue-form>
		<div v-show="active === 2">
			<el-divider content-position="left">规格</el-divider>
			<Specs :specsData="specsData" :title="title" :defaultSpecList="specList" :syncStock="form.stock" />
		</div>
		<div v-show="active === 4" style="margin-bottom: 20px"><avue-ueditor v-model="uText" :options="uOptions"></avue-ueditor></div>
		<div class="align-center-button">
			<el-button size="small" @click="handleClose">取消</el-button>
			<el-button type="primary" size="small" v-show="active !== 1" @click="previousStep">上一步</el-button>
			<el-button type="primary" size="small" v-if="title != '查看' || active != 4" @click="nextStep">{{ active === 4 ? '确定' : '下一步' }}</el-button>
		</div>

		<IsTian v-if="frameDatatype" :frameDatatype="frameDatatype"></IsTian>
		<Module v-if="moduleType" :moduleType="moduleType"/>
	</div>
</template>

<script>
import { imageUrl, actionUrl } from '@/config/public';
import { productAdd, productUpdate, productAddParam } from '@/api/product/list';
import Specs from './specs';
import IsTian from './isTian.vue';
import Module from './module';
export default {
	name: 'add',
	components: {
		Specs,
		IsTian,
		Module
	},
	props: {
		id: {
			default: '0',
			type: String
		},
		title: {
			default: '新增',
			type: String
		},
		dialogVisible: {
			default: true,
			type: Boolean
		},
		formData: Object
	},
	data() {
		return {
			frameDatatype: false,
			active: 1,
			form:
				this.title == '新增'
					? {
							name: '',
							state: 1,
							mainGraph: '',
							images: '',
							homeClassify: '',
							classifyId: '',
							seriesClassifyId: '',
							isTian: 2,
							frameId: '',
							frameProductName: '',
							isFrameProduct: 2
					}
					: this.formData.form,
			option: {
				submitBtn: false,
				emptyBtn: false,
				labelWidth: 120,
				column: [
					{
						label: '商品名称',
						span: 24,
						prop: 'name',
						rules: [
							{
								required: true,
								message: '请输入商品名称',
								trigger: 'blur'
							}
						],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '商品主图',
						type: 'upload',
						listType: 'picture-img',
						span: 24,
						row: true,
						prop: 'mainGraph',
						loadText: '附件上传中，请稍等',
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						// rules: [
						// 	{
						// 		required: true,
						// 		message: '请上传主图',
						// 		trigger: 'blur'
						// 	}
						// ],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '轮播图',
						prop: 'images',
						listType: 'picture-card',
						dataType: 'string',
						type: 'upload',
						span: 24,
						loadText: '附件上传中，请稍等',
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						// rules: [
						// 	{
						// 		required: true,
						// 		message: '请上传商品轮播图',
						// 		trigger: 'blur'
						// 	}
						// ],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '商品状态',
						prop: 'state',
						type: 'radio',
						span: 12,
						dicData: [
							{
								label: '上架',
								value: 1
							},
							{
								label: '下架',
								value: 2
							}
						],
						rules: [
							{
								required: true,
								message: '请选择商品状态',
								trigger: 'blur'
							}
						],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '边框',
						prop: 'isFrame',
						type: 'radio',
						span: 12,
						dicData: [
							{
								label: '自带',
								value: 1
							},
							{
								label: '不带',
								value: 2
							},
							{
								label: '不能联排',
								value: 3
							}
						],
						rules: [
							{
								required: true,
								message: '请选择是否带边框',
								trigger: 'blur'
							}
						],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '排序',
						prop: 'sort',
						type: 'number',
						rules: [
							{
								required: true,
								message: '请输入排序',
								trigger: 'blur'
							}
						],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '首页分类',
						prop: 'homeClassify',
						filter: false,
						type: 'tree',
						dicData: [],
						multiple: true,
						props: {
							label: 'name',
							value: 'id'
						},
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					// {
					// 	label: '产品分类',
					// 	prop: 'classifyId',
					// 	filter: false,
					// 	type: 'tree',
					// 	dicData: this.$store.state.product.productTree,
					// 	props: {
					// 		label: 'name',
					// 		value: 'id'
					// 	},
					// 	display: true,
					// 	disabled: this.title == '查看' ? true : false
					// },
					{
						label: '产品分类',
						prop: 'classifyId',
						filter: false,
						type: "cascader",
						dicData: this.$store.state.product.productTree,
						props: {
							label: "name",
							value: 'id'
						},
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '系列分类',
						prop: 'seriesClassifyId',
						filter: false,
						type: "cascader",
						dicData: this.$store.state.product.classifyTree,
						props: {
							label: "name",
							value: 'id'
						},
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					// {
					// 	label: '系列分类',
					// 	prop: 'seriesClassifyId',
					// 	filter: false,
					// 	type: 'tree',
					// 	dicData: this.$store.state.product.classifyTree,
					// 	props: {
					// 		label: 'name',
					// 		value: 'id'
					// 	},
					// 	display: true,
					// 	disabled: this.title == '查看' ? true : false
					// },
					{
						label: '边框产品',
						prop: 'isFrameProduct',
						type: 'radio',
						dicData: [{ label: '是', value: 1 }, { label: '否', value: 2 }],
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '边框产品孔数量',
						prop: 'frameProductNum',
						display: true,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '商品价格',
						span: 12,
						prop: 'price',
						type: 'number',
						append: '元',
						rules: [
							{
								required: true,
								message: '请输入商品名称',
								trigger: 'blur'
							}
						],
						display: false,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '商品库存',
						span: 12,
						prop: 'stock',
						type: 'number',
						rules: [
							{
								required: true,
								message: '请输入商品库存',
								trigger: 'blur'
							}
						],
						display: false,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '参数背景图',
						prop: 'paramImage',
						type: 'upload',
						listType: 'picture-img',
						span: 24,
						row: true,
						loadText: '附件上传中，请稍等',
						propsHttp: {
							res: 'data',
							url: 'url',
							name: 'path',
							home: imageUrl
						},
						headers: {
							Authorization: this.$store.state.user.Token
						},
						action: actionUrl,
						rules: [
							{
								message: '请上传参数配置背景图',
								trigger: 'blur'
							}
						],
						display: false,
						disabled: this.title == '查看' ? true : false
					},
					{
						label: '参数模板',
						prop: 'paramButton',
						slot: true,
						span: 24,
						display: false
					},
					{
						label: '参数详情',
						prop: 'details',
						slot: true,
						span: 24,
						display: false,
						disabled: this.title == '查看' ? true : false
					}
				]
			},
			paramData:
				this.title == '新增'
					? [
							{
								key: '',
								value: ''
							}
					  ]
					: this.formData.paramData,
			option1: {
				sortable:true,
				border: false,
				editBtn: false,
				delBtn: false,
				align: 'left',
				menuAlign: 'center',
				menuWidth: this.title == '查看' ? 1 : 100,
				column: [
					{
						label: '参数名称',
						prop: 'key',
						width: 150,
						slot: true
					},
					{
						label: '内容',
						prop: 'value'
					}
				]
			},
			specsData: this.title == '新增' ? [] : this.formData.specsData,
			specList: this.title == '新增' ? [] : this.formData.specList,
			uText: this.title == '新增' ? '' : this.formData.uText,
			uOptions: {
				action: actionUrl,
				headers: {
					Authorization: this.$store.state.user.Token
				},
				customConfig: {},
				props: {
					url: 'path',
					res: 'data',
					name: 'path',
					home: imageUrl
				}
			},
			paramButtons: [], //商品列表-新增-参数选择
			moduleType: false,
			paramName: '',
		};
	},
	watch: {
		active(newValue) {
			const name = this.findObject(this.option.column, 'name');
			const state = this.findObject(this.option.column, 'state');
			const mainGraph = this.findObject(this.option.column, 'mainGraph');
			const images = this.findObject(this.option.column, 'images');
			const isFrame = this.findObject(this.option.column, 'isFrame');
			const sort = this.findObject(this.option.column, 'sort');
			const homeClassify = this.findObject(this.option.column, 'homeClassify');
			const classifyId = this.findObject(this.option.column, 'classifyId');
			const seriesClassifyId = this.findObject(this.option.column, 'seriesClassifyId');
			const isFrameProduct = this.findObject(this.option.column, 'isFrameProduct');
			const frameProductNum = this.findObject(this.option.column, 'frameProductNum');

			const price = this.findObject(this.option.column, 'price');
			const stock = this.findObject(this.option.column, 'stock');

			const paramImage = this.findObject(this.option.column, 'paramImage');
			const details = this.findObject(this.option.column, 'details');
			const paramButton = this.findObject(this.option.column, 'paramButton');

			name.display = newValue == 1 ? true : false;
			state.display = newValue == 1 ? true : false;
			mainGraph.display = newValue == 1 ? true : false;
			images.display = newValue == 1 ? true : false;
			isFrame.display = newValue == 1 ? true : false;
			sort.display = newValue == 1 ? true : false;
			homeClassify.display = newValue == 1 ? true : false;
			classifyId.display = newValue == 1 ? true : false;
			seriesClassifyId.display = newValue == 1 ? true : false;
			isFrameProduct.display = newValue == 1 ? true : false;
			if (this.form.isFrameProduct == 1 && newValue == 1) {
				frameProductNum.display = newValue == 1 ? true : false;
			}

			price.display = newValue == 2 ? true : false;
			stock.display = newValue == 2 ? true : false;

			details.display = newValue == 3 ? true : false;
			paramImage.display = newValue == 3 ? true : false;
			paramButton.display = newValue == 3 && this.title != '查看' ? true : false;
		},
		'form.isFrameProduct'(newValue) {
			console.log(newValue);
			const frameProductNum = this.findObject(this.option.column, 'frameProductNum');
			if (this.active == 1) {
				frameProductNum.display = newValue == 1 ? true : false;
			}
		},
		'form.seriesClassifyId'(value){
			console.log(value);
			if(value != ''){
				value.split(',').forEach((item,index)=>{
					if(index == value.split(',').length - 1){
						this.form.seriesClassifyIdNewValue = item
						console.log(item)
					}
				})
			}
		},
		'form.classifyId'(value){
			console.log(value);
			if(value != ''){
				value.split(',').forEach((item,index)=>{
					if(index == value.split(',').length - 1){
						this.form.classifyIdNewValue = item
						console.log(item)
					}
				})
			}
		}
	},
	mounted() {

		const frameProductNum = this.findObject(this.option.column, 'frameProductNum');
		if (this.title == '新增') {
			frameProductNum.display = false;
		} else {
			frameProductNum.display = this.formData.form.isFrameProduct == 1 ? true : false;
		}

		this.GetProductAddParam();

		const homeClassify = this.findObject(this.option.column, 'homeClassify');
		homeClassify.dicData = this.$store.state.product.classifyHome;
		const classifyId = this.findObject(this.option.column, 'classifyId');
		classifyId.dicData = this.$store.state.product.productTree;
		// const seriesClassifyId = this.findObject(this.option.column, 'seriesClassifyId');
		// seriesClassifyId.dicData = this.$store.state.product.classifyTree;

		this.$root.eventHub.$on('changeSpecs', res => {
			// console.log(res)
			this.specsData = res;
		});
		this.$root.eventHub.$on('changeSpecification', res => {
			// console.log(res)
			this.specList = res;
		});

		this.$root.eventHub.$on('clickChoiceframeId', n => {
			console.log(n);
			this.form.frameId = n.frameId;
			this.form.frameProductName = n.frameProductName;
			this.frameDatatype = false;
		});

		this.$root.eventHub.$on('changeModule', res => {
			console.log(res)
			this.moduleType = false;
			if(res.type){
				this.paramName = res.data.name;
				this.form.paramImage = res.data.paramImage;
				this.paramData = res.data.param;
			}
		});
	},
	methods: {
		sortableChange(oldindex, newindex, row, list) {
			this.paramData=[]
			this.$nextTick(()=>{
				this.paramData=list;
			})
			// console.log(oldindex, newindex, row, list)
		},
		clickFrameId() {
			this.frameDatatype = true;
		},
		clickAdd() {
			this.paramData.push({
				key: '',
				value: ''
			});
		},
		clickCel(index) {
			this.paramData.splice(index, 1);
		},
		nextStep() {
			if (this.active === 4) {
				this.$refs.form.submit();
			} else {
				this.$refs.form.validate((vaild, done) => {
					if (vaild) {
						if (this.active === 2) {
							if (this.specsData.length == 0) {
								this.active = this.active + 1;
								done();
							} else {
								let spacsState = false;
								this.specsData.forEach(item => {
									item.value.forEach(itemA => {
										if (itemA.content == '' || item.key == '') {
											spacsState = false;
										} else {
											spacsState = true;
										}
									});
								});
								if (spacsState) {
									this.active = this.active + 1;
									done();
								} else {
									this.$message.error('请填写完整规格');
								}
							}
						} else {
							this.active = this.active + 1;
							done();
						}
					}
				});
			}
		},
		previousStep() {
			this.active = this.active - 1;
		},
		formSubmit(form, done) {
			if (this.title == '新增') {
				this.formSubmitAdd(form, done);
			} else {
				this.formSubmitEdit(form, done);
			}
		},
		formSubmitAdd(form, done) {
			let images = form.images.split(',');
			for (let i = 0; i < images.length; i++) {
				if (images[i] == '') {
					images.splice(i, 1);
					i = i - 1;
				}
			}
			productAdd({
				mainGraph: form.mainGraph,
				name: form.name,
				state: form.state,
				isFrame: form.isFrame,
				sort: form.sort,
				classifyId: form.classifyIdNewValue,
				classifyStr: form.classifyId,
				homeClassify: form.homeClassify,
				images: images.toString(),
				paramImage: form.paramImage,
				price: form.price,
				seriesClassifyId: form.seriesClassifyIdNewValue,
				seriesStr: form.seriesClassifyId,
				stock: form.stock,
				details: this.uText,
				param: this.paramData,
				spec: this.specsData,
				specList: this.specList,
				isFrameProduct: form.isFrameProduct,
				frameProductNum: form.frameProductNum
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.$root.eventHub.$emit('changeProduct', true);
					done();
				} else {
					this.$message.error(res.msg);
					done();
				}
			});
		},
		formSubmitEdit(form, done) {
			let images = form.images.split(',');
			for (let i = 0; i < images.length; i++) {
				if (images[i] == '') {
					images.splice(i, 1);
					i = i - 1;
				}
			}
			productUpdate({
				id: this.id,
				mainGraph: form.mainGraph,
				name: form.name,
				state: form.state,
				isFrame: form.isFrame,
				sort: form.sort,
				classifyId: form.classifyIdNewValue,
				classifyStr: form.classifyId,
				homeClassify: form.homeClassify,
				images: images.toString(),
				paramImage: form.paramImage,
				price: form.price,
				seriesClassifyId: form.seriesClassifyIdNewValue,
				seriesStr: form.seriesClassifyId,
				stock: form.stock,
				details: this.uText,
				param: this.paramData,
				spec: this.specsData,
				specList: this.specList,
				isFrameProduct: form.isFrameProduct,
				frameProductNum: form.frameProductNum
			}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.$root.eventHub.$emit('changeProduct', true);
					done();
				} else {
					this.$message.error(res.msg);
					done();
				}
			});
		},
		handleClose() {
			this.$root.eventHub.$emit('changeProduct', false);
		},
		//商品列表-新增-参数选择
		GetProductAddParam() {
			productAddParam().then(res => {
				this.paramButtons = res.data;
			});
		},
		paramButton(form) {
			this.$nextTick(() => {
				this.form.paramImage = form.paramImage;
				this.paramData = form.param;
			});
		},
		paramButtonModule(){
			this.moduleType = true;
		},
		uploadAfter(res, done, loading, column) {
			done();
			// this.$message.success('上传后的方法')'
			this.$nextTick(() => {
				if (column.prop == 'mainGraph') {
					this.form.mainGraph = res.path;
				} else if (column.prop == 'paramImage') {
					this.form.paramImage = res.path;
				} else {
					if (this.form.images == '') {
						this.form.images = res.path;
					} else {
						this.form.images += res.path + ',';
					}
				}
			});
		},
		uploadDelete() {
			return this.$confirm(`是否确定移除？`);
		},
		uploadError(error, column) {
			this.$message.error('上传失败');
			console.log(error, column);
		}
	}
};
</script>

<style scoped>
.avue-form >>> .el-upload {
	width: 100px;
	height: 100px;
}
.avue-form >>> .el-icon-plus {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
.avue-form >>> .el-upload--picture-card {
	line-height: 100px;
}
.avue-form >>> .avue-upload__avatar {
	width: 100px;
	height: 100px;
}
.avue-form >>> .el-upload-list__item,
.el-upload-list__item-actions {
	width: 100px;
	height: 100px;
}
.align-center-button {
	display: flex;
	justify-content: center;
}
.avue-crud >>> .avue-crud__menu {
	height: 0;
	min-height: 0;
}
.avue-crud {
	border: 1px solid #e6e6e6;
}
.avue-crud >>> .avue-crud__menu {
	margin-bottom: 0px;
}
.avue-crud >>> .el-table__cell .cell input {
	width: 80%;
	height: 30px;
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	padding: 0 10px;
}
.el-steps--simple {
	padding: 13px 3%;
}
</style>
