<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { projectAdd,projectUpdate } from '@/api/person/project';
    import { getArea } from '@/api/index';
    export default {
        name: "project",
        props: {
            title: String,
            projectViewForm: Object,
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: this.projectViewForm,
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label: '名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入名称",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '大区',
                            prop: 'regionId',
                            type: 'select',
                            dicData: this.$store.state.office.regionList,
                            props: {
                                label: "name",
                                value: 'id'
                            },
                            rules: [{
                                required: true,
                                message: "请选择大区",
                                trigger: "blur"
                            }],
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '省/市/县区',
                            prop: 'area',
                            type: "cascader",
                            props: {
                                label: 'name',
                                value: 'id'
                            },
                            lazy: true,
                            lazyLoad(node, resolve) {
                                let stop_level = 2;
                                let level = node.level;
                                let data = node.data || {};
                                let list = [];
                                let callback = () => {
                                    resolve((list || []).map(ele => {
                                        return Object.assign(ele, {
                                            leaf: level >= stop_level
                                        })
                                    }));
                                }
                                if (level == 0) {
                                    getArea('0').then( res => {
                                        list = res.data;
                                        callback()
                                    })
                                }
                                if (level == 1) {
                                    getArea(data.id).then( res => {
                                        list = res.data;
                                        callback()
                                    })
                                } else if (level == 2) {
                                    getArea(data.id).then( res => {
                                        list = res.data;
                                        callback()
                                    })
                                }else{
                                    callback()
                                }
                            },
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        }
                    ]
                }
            }
        },
        methods: {
            formSubmit(form,done){
                // console.log(form)
                if(this.title == '新增'){
                    return this.formSubmitRoleAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitRoleUpdate(form,done)
                }
            },
            // 新增
            formSubmitRoleAdd(form,done){
                projectAdd({
                    name: form.name,
                    regionId: form.regionId,
                    area: form.area
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeProject', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitRoleUpdate(form,done){
                projectUpdate({
                    id: form.id,
                    name: form.name,
                    regionId: form.regionId,
                    area: form.area
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeProject', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeProject', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>