<template>
    <div class="main-content">
		<avue-data-panel :option="option"></avue-data-panel>
    </div>
</template>

<script>
	import { countNum } from "@/api";
    export default {
        name: 'index',
        data(){
            return {
				option: {
					span:8,
					data: [
						{
						  title: '所有用户数量',
						  type: 'allUserNum',
						  count: '0',
						  icon: 'iconfont icon-24gf-portraitMaleInfo2',
						  color: '#00a7d0',
						},
						{
						  title: '后台用户数量',
						  type: 'webUserNum',
						  count: '0',
						  icon: 'iconfont icon-24gf-portraitMaleInfo2',
						  color: 'rgb(27, 201, 142)',
						},
						{
						  title: '小程序用户数量',
						  type: 'appletUserNum',
						  count: '0',
						  icon: 'iconfont icon-24gf-portraitMaleInfo2',
						  color: 'rgb(230, 71, 88)',
						},
						{
						  title: '商品数量',
						  type: 'skuNum',
						  count: '0',
						  icon: 'iconfont icon-shangpin',
						  color: '#00a7d0',
						},
						{
						  title: '订单数量',
						  type: 'orderNum',
						  count: '0',
						  icon: 'iconfont icon-quanbudingdan',
						  color: 'rgb(27, 201, 142)',
						},
						{
						  title: '待处理订单数量',
						  type: 'needNum',
						  count: '0',
						  icon: 'iconfont icon-daichuli',
						  color: 'rgb(230, 71, 88)',
						}
					]
				}
			}
        },
		mounted() {
			let that = this;
			that.GetcountNum();
			// console.log(this.option.data)
		},
        methods: {
			GetcountNum(){
				let that = this;
				countNum().then( res => {
					// console.log(res.data);
					// console.log(keys(res.data));
					that.option.data.forEach( item => {
						if(item.type == 'allUserNum') item.count = res.data.allUserNum;
						if(item.type == 'webUserNum') item.count = res.data.webUserNum;
						if(item.type == 'appletUserNum') item.count = res.data.appletUserNum;
						if(item.type == 'skuNum') item.count = res.data.skuNum;
						if(item.type == 'orderNum') item.count = res.data.orderNum;
						if(item.type == 'needNum') item.count = res.data.needNum;
					})
				})
			}
        }
    }
</script>

<style scoped>
	.avue-data-panel >>> .item {
		margin-bottom: 20px;
	}
	.avue-data-panel >>> .iconfont{
		font-size: 30px;
	}
</style>