<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">

        <avue-form :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-after="uploadAfter"
                   @submit="formSubmit">

            <template slot="goods">
                <avue-crud :data="goodsData"
                           :option="goodsOption"
                           :page.sync="pageSync"
                           @on-load="onLoad">

                    <template slot-scope="{row}" slot="menu">
                        <div :class="row.id == typeId ? 'active':''" style="cursor: pointer" @click="clickChoice(row)">{{row.id == typeId ? '已选择':'选择'}}</div>
                    </template>
                </avue-crud>
            </template>

        </avue-form>

    </el-dialog>
</template>

<script>
    import { advertAdd,advertUpdate } from "@/api/system/advert";
    import { imageUrl,actionUrl } from '@/config/public';
    import { productList } from "@/api/product/list";
    export default {
        name: "advert",
        props: {
            title: {
                default: '新增',
                type: String
            },
            projectViewForm: Object,
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        watch:{
            'form.type'(newValue){
                const column = this.findObject(this.option.column, "goods");
                if(newValue === 1){
                    column.display = true;
                }else{
                    column.display = false;
                }
            }
        },
        data(){
            return{
                form: this.projectViewForm,
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label: '图片',
                            prop: 'image',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 24,
                            loadText: '附件上传中，请稍等',
                            action: actionUrl,
                            propsHttp:{
                                res:'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            rules: [{
                                required: true,
                                message: "请上传图片",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '名称',
                            span: 12,
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入名称",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '排序',
                            span: 12,
                            prop: 'sort',
                            type: 'number',
                            rules: [{
                                required: true,
                                message: "请输入排序",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '位置',
                            prop: 'position',
                            type: 'select',
                            span: 12,
                            dicData: [
                                {
                                    label: '首页轮播位置',
                                    value: 1
                                }
                            ],
                            rules: [{
                                required: true,
                                message: "请选择位置",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            type: 'radio',
                            span: 12,
                            dicData: [{label: '上架',value: 1},{label: '下架',value: 2}],
                            rules: [{
                                required: true,
                                message: "请选择状态",
                                trigger: "blur"
                            }],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '跳转类型',
                            prop: 'type',
                            type: 'select',
                            span: 12,
                            dicData: [
                                {
                                    label: '产品',
                                    value: 1
                                }
                            ],
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                        },
                        {
                            label: '跳转商品',
                            prop: 'goods',
                            span: 24,
                            slot: true,
                            display: false
                        },
                    ]
                },
                goodsData: [],
                goodsOption:{
                    header: false,
                    showHeader: false,
                    border: true,
                    align:'center',
                    menuAlign:'center',
                    addBtn: false,
                    editBtn: false,
                    delBtn:false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新,
                    menuWidth: 100,
                    column:[
                        {
                            label: '主图',
                            prop: 'mainGraph',
                            type: 'upload',
                            listType: 'picture-img',
                            width: 100,
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label:'名称',
                            prop:'name',
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                typeId: ''
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    return this.formSubmitAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitUpdate(form,done)
                }
            },
            // 新增
            formSubmitAdd(form,done){
                advertAdd({
                    image: form.image,
                    name: form.name,
                    position: form.position,
                    sort: form.sort,
                    state: form.state,
                    type: form.type,
                    typeId: this.typeId,
                }).then(res => {
                    console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeAdvert', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitUpdate(form,done){
                advertUpdate({
                    id: form.id,
                    image: form.image,
                    name: form.name,
                    position: form.position,
                    sort: form.sort,
                    state: form.state,
                    type: form.type,
                    typeId: this.typeId,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeAdvert', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeAdvert', false);
                    })
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            },
            uploadAfter(res, done) {
                done()
                this.$nextTick(() => {
                    this.form.image = res.path;
                })
            },
            onLoad(){
                this.list();
            },
            list(){
                productList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then(res => {
                    if(res.code === 200){
                        this.goodsData = res.data.records;
                        this.pageSync.currentPage = res.data.current;
                        this.pageSync.total = res.data.total;
                    }
                })
            },
            clickChoice(form){
                this.typeId = form.id;
            }
        }
    }
</script>

<style scoped>
    .active{
        color: #409eff;
    }
</style>