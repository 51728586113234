<template>
    <el-dialog
            title="新增"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-before="uploadBefore"
                   :upload-after="uploadAfter"
                   @submit="formSubmit">
            <template slot="codesType" slot-scope="{item}">
                <span>{{ item }}</span>
            </template>
        </avue-form>
    </el-dialog>
</template>

<script>
    import { imageUrl,actionUrl } from '@/config/public';
    import { userAdd } from "@/api/system/index";
    export default {
        name: "add",
        props: {
            dialogVisible: Boolean
        },
        data(){
            return{
                form: {},
                option: {
                    column: [
                        {
                            label: '头像',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 14,
                            row: true,
                            prop: 'headPortrait',
                            loadText: '附件上传中，请稍等',
                            tip: '只能上传jpg/png用户头像，且不超过500kb',
                            propsHttp:{
                                res:'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            rules: [{
                                required: true,
                                message: "请上传头像",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'登录名',
                            prop:'username',
                            rules: [{
                                required: true,
                                message: "请输入登录名",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'登录密码',
                            prop:'password',
                            rules: [{
                                required: true,
                                message: "请输入登录密码",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'用户姓名',
                            prop:'fullName',
                            rules: [{
                                required: true,
                                message: "请输入用户姓名",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'手机号码',
                            prop:'phone',
                            rules: [{
                                required: true,
                                message: "请输入手机号码",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "所属角色",
                            prop: "role",
                            type: "select",
                            dicData: this.$store.state.system.role,
                            span: 24,
                            multiple:true,
                            props: {
                                label: 'name',
                                value: 'id'
                            },
                        }
                    ]
                }
            }
        },
        methods:{
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                //如果你想修改file文件,由于上传的file是只读文件，必须复制新的file才可以修改名字，完后赋值到done函数里,如果不修改的话直接写done()即可
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile)
                // this.$message.success('上传前的方法')
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            },
            uploadAfter(res, done) {
                done()
                // this.$message.success('上传后的方法')'
                // console.log(res)
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.form.headPortrait = res.path;
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeAdd', false);
                    })
            },
            formSubmit(form,done){
                let that = this;
                userAdd({
                    fullName: form.fullName,
                    headPortrait: form.headPortrait,
                    username: form.username,
                    password: form.password,
                    phone: form.phone,
                    roleIds: form.role.toString()
                }).then(res => {
                    // 获取数据成功后的其他操作
                    // console.log(res)
                    if(res.code === 200){
                        that.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeAdd', true);
                        done()
                    }else{
                        that.$message.error(res.msg)
                        done()
                    }
                }).catch(err =>{
                    that.$message.error(err.msg)
                    done()
                })
            }
        }
    }
</script>

<style scoped>
</style>