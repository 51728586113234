import { get,post,put,Delete } from '../axios'

// 系列分类-列表
export const seriesList = params => get('/web/product/classify/series/list',params);

// 系列分类-新增
export const seriesAdd = params => post('/web/product/classify/series/add',params);

// 系列分类-查看
export const seriesView = params => get(`/web/product/classify/series/view/${params}`);

// 系列分类-修改
export const seriesUpdate = params => put('/web/product/classify/series/update',params);

// 系列分类-删除
export const seriesDelete = params => Delete(`/web/product/classify/series/delete/${params}`);