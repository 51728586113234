<template>
    <div class="main-content">
        <avue-crud :data="data"
                   :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           @click.stop="getGoods(row)">商品</el-button>
            </template>

        </avue-crud>

        <HomeIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :id="id" :parentId="parentId"/>

        <HomeGoods v-if="dialogVisibleGoods" :dialogVisibleGoods="dialogVisibleGoods" :classifyID="classifyID" :classifyTitle="classifyTitle"/>

    </div>
</template>

<script>
    import { homeList,homeDelete } from '@/api/product/home';

    import { imageUrl } from '@/config/public';
    import HomeIndex from "@/components/product/home/index";
    import HomeGoods from "@/components/product/home/goods";

    export default {
        name: 'classify',
        components: {
            HomeIndex,
            HomeGoods
        },
        data() {
            return {
                Buttons: [],
                title: '',
                id: '',
                parentId: '',
                dialogVisible: false,
                classifyID: '',
                classifyTitle: '',
                dialogVisibleGoods: false,
                form: {
                    image: ''
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                data: [],
                option:{
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    addBtn:false,
                    editBtn:false,
                    delBtn:false,
                    menuWidth: 300,
                    column:[
                        {
                            label: '排序',
                            prop: 'sort',
                            type: 'number',
                            width: 100,
                            align: 'center',
                            rules: [{
                                required: true,
                                message: "请输入排序",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: '图片',
                            prop: 'image',
                            type: 'upload',
                            listType: 'picture-img',
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label: '名称',
                            prop: 'name',
                            display: false
                        },
                        {
                            label: '标题',
                            prop: 'title',
                            rules: [{
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur"
                            }]
                        }
                    ]
                },
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeHome', (n) => {
                this.dialogVisible = false
                if(n){
                    this.GetHomeList();
                }
            })

            this.$root.eventHub.$on('changeGoods', (n) => {
                this.dialogVisibleGoods = false;
            })
        },
        methods: {
            onLoad(){
                this.GetHomeList();
            },
            GetHomeList() {
                homeList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then(res => {
                    if (res.code === 200) {
                        // console.log(res);
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                });
            },
            getGoods(form){
                // console.log(form);
                this.classifyID = form.id;
                this.classifyTitle = form.name;
                this.dialogVisibleGoods = true;
            },
            clickMenu(name,form){
                // console.log(name,form)
                this.title = name;
                if(name == '删除'){
                    this.rowDel(form.id);
                }else{
                    this.id = form == undefined ? '0': form.id;
                    this.parentId = form == undefined ? '0': form.parentId;
                    this.dialogVisible = true;
                }
            },
            rowDel(id){
                this.$confirm(`删除这条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            homeDelete(id).then( res => {
                                console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.GetHomeList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>