var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-steps',{staticStyle:{"margin-bottom":"20px"},attrs:{"active":_vm.active,"finish-status":"success","simple":""}},[_c('el-step',{attrs:{"title":"1.基础信息"}}),_c('el-step',{attrs:{"title":"2.规格/价格"}}),_c('el-step',{attrs:{"title":"3.商品详情"}}),_c('el-step',{attrs:{"title":"4.商品详情"}})],1),_c('avue-form',{ref:"form",attrs:{"option":_vm.option,"upload-error":_vm.uploadError,"upload-delete":_vm.uploadDelete,"upload-after":_vm.uploadAfter},on:{"submit":_vm.formSubmit},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('template',{slot:"frameId"},[_c('div',[_vm._v(_vm._s(_vm.form.frameProductName))]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.clickFrameId()}}},[_vm._v("选择")])],1),_c('template',{slot:"paramButton"},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.paramButtonModule}},[_vm._v("选择模板")]),(_vm.paramName != '')?_c('div',{staticStyle:{"color":"#409EFF","margin-left":"20px"}},[_vm._v("已选："+_vm._s(_vm.paramName))]):_vm._e()],1)]),_c('template',{slot:"details"},[_c('avue-crud',{attrs:{"data":_vm.paramData,"option":_vm.option1},on:{"sortable-change":_vm.sortableChange},scopedSlots:_vm._u([{key:"key",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.key),expression:"row.key"}],attrs:{"type":"text","disabled":_vm.title == '查看' ? true : false},domProps:{"value":(row.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "key", $event.target.value)}}})]}},{key:"value",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.value),expression:"row.value"}],attrs:{"type":"text","disabled":_vm.title == '查看' ? true : false},domProps:{"value":(row.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "value", $event.target.value)}}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(index == 0)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus-outline"},on:{"click":function($event){$event.stopPropagation();return _vm.clickAdd.apply(null, arguments)}}},[_vm._v("添加")]):_vm._e(),(index != 0)?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-circle-close"},on:{"click":function($event){$event.stopPropagation();return _vm.clickCel(index)}}},[_vm._v("删除")]):_vm._e()]}}])})],1)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 2),expression:"active === 2"}]},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v("规格")]),_c('Specs',{attrs:{"specsData":_vm.specsData,"title":_vm.title,"defaultSpecList":_vm.specList,"syncStock":_vm.form.stock}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === 4),expression:"active === 4"}],staticStyle:{"margin-bottom":"20px"}},[_c('avue-ueditor',{attrs:{"options":_vm.uOptions},model:{value:(_vm.uText),callback:function ($$v) {_vm.uText=$$v},expression:"uText"}})],1),_c('div',{staticClass:"align-center-button"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.handleClose}},[_vm._v("取消")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.active !== 1),expression:"active !== 1"}],attrs:{"type":"primary","size":"small"},on:{"click":_vm.previousStep}},[_vm._v("上一步")]),(_vm.title != '查看' || _vm.active != 4)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.active === 4 ? '确定' : '下一步'))]):_vm._e()],1),(_vm.frameDatatype)?_c('IsTian',{attrs:{"frameDatatype":_vm.frameDatatype}}):_vm._e(),(_vm.moduleType)?_c('Module',{attrs:{"moduleType":_vm.moduleType}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }