<template>
    <div class="main-content">
        <avue-crud ref="crud"
                   :data="data"
                   :option="option"
                   :page.sync="pageSync"
                   @on-load="onLoad"
        >
            <template slot="menuLeft">

                <el-button v-for="(item,index) in Buttons" :key="index"
                           type="primary"
                           :icon="item.icon"
                           size="small"
                           v-show="item.name == '新增'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

        </avue-crud>
        <Email v-if="emailData.type" :emailData="emailData"/>
    </div>
</template>

<script>
    import { emailList,emailDelete } from "@/api/system/email"
    import Email from "@/components/system/email/email"
    export default {
        name: "email",
        components: {
            Email
        },
        data(){
            return{
                Buttons: this.$store.state.index.menuSubButtons,
                emailData: {
                    type: false,
                    id: ''
                },
                data: [],
                option: {
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    viewBtn: false,
                    align: 'center',
                    menuAlign: 'center',
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    column: [
                        {
                            label: '邮箱地址',
                            prop: 'email'
                        },
                        {
                            label: '状态', //1-启用；2-禁用
                            prop: 'state',
                            type: 'radio',
                            dicData: [
                                {
                                    label: '启用',
                                    value: 1
                                },
                                {
                                    label: '禁用',
                                    value: 2
                                }
                            ]
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime'
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,pager,prev, next',
                    background: false,
                    pageSize: 10
                }
            }
        },
        mounted() {
            this.$root.eventHub.$on('changeEmail', (n)=>{
                this.emailData =  {
                    type: false
                };
                if(n){
                    this.GET_emailList();
                }
            })
        },
        methods: {
            clickMenu(name,row){
                console.log(name,row)
                if(name == '删除'){
                    console.log('删除',name)
                    this.$confirm('是否确认删除？', '删除', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',

                    }).then(() => {
                            emailDelete(row.id).then(res => {
                                if (res.code === 200) {
                                    this.$message.success('操作成功');
                                    this.GET_emailList();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            }).catch(err => {
                                this.$message.error(err.msg);
                            });
                        })
                        .catch(() => {});
                }else{
                    console.log(name)
                    this.emailData.type = true;
                    if(row == undefined){
                        this.emailData.id = 0;
                    }else{
                        this.emailData.id = row.id;
                    }
                }
            },
            onLoad(){
                this.GET_emailList();
            },
            GET_emailList(){
                emailList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then(res => {
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>