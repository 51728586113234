import { get,post,put,Delete } from '../axios'

// 产品分类-列表
export const classifyList = params => get('/web/product/classify/list',params);

// 产品分类-新增
export const classifyAdd = params => post('/web/product/classify/add',params);

// 产品分类-查看
export const classifyView = params => get(`/web/product/classify/view/${params}`);

// 产品分类-修改
export const classifyUpdate = params => put('/web/product/classify/update',params);

// 产品分类-删除
export const classifyDelete = params => Delete(`/web/product/classify/delete/${params}`);
