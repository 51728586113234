import { get,post,put,Delete } from '../axios'

// 办事处管理-列表
export const officeList = params => get('/web/person/office/list',params);

// 办事处管理-新增
export const officeAdd = params => post('/web/person/office/add',params);

// 办事处管理-查看
export const officeView = params => get(`/web/person/office/view/${params}`);

// 办事处管理-修改
export const officeUpdate = params => put('/web/person/office/update',params);

// 办事处管理-删除
export const officeDelete = params => Delete(`/web/person/office/delete/${params}`);

// 办事处管理-大区选择
export const officeRegionList = params => get('/web/person/office/region/list',params);