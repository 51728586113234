import { get,post,put,Delete } from '../axios'

/* 商品管理-列表 */
export const productList = params => get('/web/product/list',params);

// 商品列表-新增
export const productAdd = params => post('/web/product/add',params);

// 商品列表-删除
export const productDelete = params => Delete(`/web/product/delete/${params}`);

// 商品列表-查看
export const productView = params => get(`/web/product/view/${params}`);

// 商品列表-修改
export const productUpdate = params => put('/web/product/update',params);

// 商品列表-首页分类-选择
export const classifyHome = params => get('/web/product/classify/home',params);

// 商品列表-系列分类-树形结构
export const classifyTree = params => get('/web/product/series/classify/tree',params);

// 商品列表-产品分类-树形结构
export const productTree = params => get('/web/product/classify/product/tree',params);

// 商品列表-状态-修改
export const productStateUpdate = params => put('/web/product/state/update',params);

// 商品列表-新增-参数选择
export const productAddParam = params => get('/web/product/add/param',params);

// 商品列表-批量修改排序
export const productSortBatchUpdate = params => put('/web/product/product/sort/batch/update',params);

// 商品列表-修改
export const productDiscountUpdate = params => put('/web/product/product/discount/update',params);

// 商品系列分类-批量修改
export const productSeriesClassifyUpdate = params => put('/web/product/series/classifyUpdate',params);

// 产品分类-批量修改
export const productClassifyUpdate = params => put('/web/product/classifyUpdate',params);
