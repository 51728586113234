import {get, post, put,Delete} from '../axios'
/* 公告管理-列表 */
export const noticeList = params => get('/web/system/notice/list',params);

// 公告管理-查看
export const noticeView = params => get(`/web/system/notice/view/${params}`);

// 公告管理-修改
export const noticeUpdate = params => put('/web/system/notice/update',params);

// 公告管理-新增
export const noticeAdd = params => post('/web/system/notice/add',params);

// 公告管理-删除
export const noticeDelete = params => Delete(`/web/system/notice/delete/${params}`);
