<template>
    <div class="main-content">
        <avue-crud
                :data="data"
                :option="option"
                :page.sync="pageSync"
                @selection-change="selectionChange"
                @on-load="onLoad"
                ref="crud">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="(item.name != '修改') && (item.name != '查看')"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

            <template slot="state" slot-scope="scope" >
                <el-tag>{{scope.row.state == 1 ? "上架" : "下架"}}</el-tag>
            </template>

        </avue-crud>

        <Notice v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :projectViewForm="projectViewForm"/>
    </div>
</template>

<script>
    import { noticeList,noticeDelete,noticeView } from "@/api/system/notice"
    import Notice from "@/components/system/notice/notice"
    export default {
        name: "notice",
        components: {
            Notice
        },
        data(){
            return{
                id: '',
                title: '',
                dialogVisible: false,
                projectViewForm: {},
                Buttons: [],
                data: [],
                option: {
                    emptyText: '暂无数据',
                    border:true,
                    selection:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    column:[
						{
						    label: '标题',
						    prop: 'title',
						},
                        {
                            label: '内容',
                            prop: 'content',
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            slot: true
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                selectionList: []
            }
        },
        mounted() {
            let that = this;
            that.Buttons = that.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeNotice', (n) => {
                this.dialogVisible = false;
                this.projectViewForm = {};
                if(n) {
                    this.onLoad();
                }
            })
        },
        methods: {
            clickMenu(name,form){
                this.title = name;
                if(name == '新增'){
                    this.dialogVisible = true;
                }else if(name == '删除'){
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }else{
                    return this.GetNoticeView(form.id);
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                noticeDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }
            },
            onLoad(){
                this.GetNoticeList();
            },
            GetNoticeList(){
                noticeList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then( res => {
                    this.data = res.data.records;
                    this.pageSync.total = res.data.total;
                    this.pageSync.currentPage = res.data.current;
                })
            },
            GetNoticeView(id){
                noticeView(id).then( res => {
                    // console.log(res)
                    this.dialogVisible = true;
                    this.projectViewForm = res.data;
                })
            },
        }
    }
</script>

<style scoped>

</style>