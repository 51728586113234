const person = {
    state: {
        id: '',
    },
    mutations: {
        SetId(state,info){
            state.id = info
        }
    }

}
export default person