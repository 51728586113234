<template>
	<el-dialog
	        title="用户列表"
	        :visible.sync="dialogVisibleUser"
	        width="60%"
	        :before-close="handleClose">
			
			<avue-crud :data="data" :option="option"
			           :page.sync="pageSync"
					   :table-loading="loading"
			           @on-load="onLoad">
			
			</avue-crud>
			
	</el-dialog>
</template>

<script>
	import { projectCodeUserList } from "@/api/person/project"
	export default {
		name: 'User',
		props: {
			userCode: String,
			dialogVisibleUser: Boolean,
		},
		data(){
			return{
				loading: true,
				data: [],
				option: {
					header: false,
                    border: true,
                    align:'center',
                    menuAlign:'center',
                    addBtn: false,
                    editBtn: false,
                    delBtn:false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    menu: false,
					column:[
					    {
					        label:'姓名',
					        prop:'username',
					    },
					    {
					        label:'手机号',
					        prop:'phone',
					    },
					    {
					        label:'创建时间',
					        prop:'createTime',
					    }
					]
				},
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
			}
		},
		methods:{
			onLoad(){
				this.GetProjectCodeUserList();
			},
			GetProjectCodeUserList(){
				projectCodeUserList({
					id: this.userCode,
					page: this.pageSync.currentPage,
				    size: this.pageSync.pageSize,
				}).then(res => {
				    if (res.code === 200) {
				        // console.log(res);
						this.$nextTick(()=>{
						    this.loading = false;
							this.data = res.data.records;
							this.pageSync.total = res.data.total;
							this.pageSync.currentPage = res.data.current;
						})
				    }
				});
			},
			handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeProjectUser', false)
                    })
			}
		}
	}
</script>

<style>
</style>
