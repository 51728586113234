import Vue from 'vue'
import Vuex from 'vuex'
import index from './modules/index'
import user from './modules/user'
import system from './modules/system'
import product from './modules/product'
import office from './modules/office'
import person from './modules/person'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        index,
        user,
        system,
        product,
        office,
        person
    }
})

export default store


