import { get,post,put,Delete } from '../axios'

// 大区管理-列表
export const regionList = params => get('/web/person/region/list',params);

// 大区管理-新增
export const regionAdd = params => post('/web/person/region/add',params);

// 大区管理-查看
export const regionView = params => get(`/web/person/region/view/${params}`);

// 大区管理-修改
export const regionUpdate = params => put('/web/person/region/update',params);

// 大区管理-删除
export const regionDelete = params => Delete(`/web/person/region/delete/${params}`);