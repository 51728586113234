<template>
	<div class="main-content">
		<avue-crud  :data="data"
					:option="option"
					:search.sync="search"
					:page.sync="pageSync"
					@on-load="onLoad"
					@search-change="searchChange"
					@selection-change="selectionChange">

			<template slot="menuLeft">
				<el-button 
				style="padding: 9px 25px;" type="primary"
							size="small"
							@click.stop="clickExport()">导出</el-button>
				<el-button v-for="(item,index) in Buttons" :key="index"
							:type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
							:icon="item.icon"
							size="small"
							:plain="item.name == '新增' ? false : true"
							v-show="item.name == '新增' || item.name == '删除'"
							@click.stop="clickMenu(item.name)">{{item.name}}</el-button>
			</template>
			<template slot="menu" slot-scope="{row}">
				<el-button v-for="(item,index) in Buttons" :key="index"
							v-show="row.state == 1 || item.name == '删除'"
							style="margin-left:10px;"
							size="small"
							type="text"
							:icon="item.name == '删除' ? item.icon : item.name == '同意' ? 'el-icon-check': 'el-icon-close'"
							@click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
				<el-button style="margin-left:10px;"
							size="small"
							type="text"
							icon="el-icon-tickets"
							@click.stop="clickMenu('商品',row)">商品</el-button>
				<el-button style="margin-left:10px;" size="small" type="text" icon="el-icon-tickets" @click.stop="clickExport(row)">导出</el-button>
			</template>

			<template slot="state" slot-scope="scope">
				<el-tag @click="SetConfigureOrderState(scope.row)"
						style="cursor: pointer">{{scope.row.state == 1 ? "待处理" : "已处理"}}</el-tag>
			</template>

		</avue-crud>

		<ConfigureOrder v-if="dialogVisible" :dialogVisible="dialogVisible" :id="id"/>

	</div>
</template>

<script>
	import { configureOrderList,configureOrderDelete,configureOrderState,configureOrderExport } from '@/api/configure/orderList';
	import ConfigureOrder from "@/components/configure/order";
	export default{
		name: 'order',
		components: {
			ConfigureOrder
		},
		data(){
			return{
				Buttons: [],
				search: {},
				form: {},
				data: [],
				option: {
					border:true,
					selection: true,
					viewBtn: false,
					addBtn: false,
					delBtn:false,
					editBtn:false,
					columnBtn:false,
					refreshBtn:false,
					menuWidth: 200,
					align:'center',
					searchMenuSpan: 6,
					searchIndex:3,
					searchIcon:true,
					column: [
						{
							label:'下单人',
							prop:'username',
							search: true,
						},
						{
							label:'用户昵称',
							prop:'nickname',
							search: true,
						},
						{
							label:'订单号',
							prop:'orderNum',
							search: true,
						},
						{
							label:'联系方式',
							prop:'phone',
							search: true,
							hide: true
						},
						{
							label:'总价',
							prop:'price'
						},
						{
							label:'订单状态',
							prop:'state',
							type: 'select',
							slot: true,
							dicData: [{label: '待处理',value: 1},{label: '已处理',value: 2}],
							search: true,
						},
						{
							label:'用户类型',
							prop:'userType',
							type: 'select',
							dicData: [{label: '办事处',value: 1},{label: '物业',value: 2}],
							search: true,
						},
						{
							label:'创建时间',
							prop:'createTime'
						}
					]
				},
				pageSync: {
					currentPage: 1,
					total: 0,
					layout: "total,pager,prev, next",
					background:false,
					pageSize: 10,
				},
				selectionList: [],

				id: '',
				dialogVisible: false
			}
		},
		mounted() {
			this.Buttons = this.$store.state.index.menuSubButtons;
			this.$root.eventHub.$on('changeConfigureOrder', (n) => {
				this.dialogVisible = false
				if(n){
					this.onLoad();
				}
			})
		},
		methods:{
			clickMenu(name,form){
				// console.log(name,form)
				if(name == '删除'){
					this.rowDel(form == undefined ? this.selectionList : form.id)
				}else{
					this.id = form.id;
					this.dialogVisible = true;
				}
			},
			clickExport(form){
				if(form == undefined && this.selectionList.length == 0){
					this.$message.error('请选择一条数据')
				}else if(form == undefined && this.selectionList.length > 0){
					this.$confirm("是否导出订单数据?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					}).then(() => {
						window.open(`/api/web/configure/order/export/${this.selectionList.toString()}`);
					});
				}else if(form && this.selectionList.length == 0){
					this.$confirm("是否导出订单数据?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					}).then(() => {
						window.open(`/api/web/configure/order/export/${form.id}`);
					});
				}
			},
			searchChange(params,done) {
				this.form.username = params.username == undefined ? '' : params.username;
				this.form.nickname = params.nickname == undefined ? '' : params.nickname;
				this.form.orderNum = params.orderNum == undefined ? '' : params.orderNum;
				this.form.phone = params.phone == undefined ? '' : params.phone;
				this.form.state = params.state == undefined ? '' : params.state;
				this.form.userType = params.userType == undefined ? '' : params.userType;
				this.onLoad();
				done();
			},
			selectionChange(list){
				let array = [];
				list.forEach(item => {
					array.push(item.id)
				})
				this.selectionList = array;
			},
			rowDel(ids){
				let length = this.selectionList.length;
				if(this.selectionList.length == 0 && ids == ''){
					this.$message.error('请选择数据')
				}else{
					this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						callback: action => {
							if(action == 'confirm'){
								configureOrderDelete(ids).then( res => {
									// console.log(res)
									if(res.code === 200){
										this.$message.success(res.msg)
										this.onLoad();
									}else{
										this.$message.error(res.msg)
									}
								})
							}
						}
					})
				}
			},
			onLoad() {
				this.GetConfigureOrderList();
			},
			GetConfigureOrderList(){
				configureOrderList({
					page: this.pageSync.currentPage,
					size: this.pageSync.pageSize,
					username: this.form.username,
					nickname: this.form.nickname,
					orderNum: this.form.orderNum,
					phone: this.form.phone,
					state: this.form.state,
					userType: this.form.userType,
				}).then( res => {
					// console.log(res)
					if(res.code === 200){
						this.data = res.data.records;
						this.pageSync.total = res.data.total;
						this.pageSync.currentPage = res.data.current;
					}
				})
			},
			SetConfigureOrderState(form){
				// console.log(form)
				this.$confirm(`处理这条数据`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					callback: action => {
						if(action == 'confirm'){
							configureOrderState({
								id: form.id,
								state: form.state == 2 ? 1 : 2
							}).then( res => {
								// console.log(res)
								if(res.code === 200){
									this.onLoad();
									this.$message.success('处理完成')
								}else {
									this.$message.error(res.msg)
								}
							})
						}
					}
				})
			},
			CelConfigureOrderDelete(ids){
				configureOrderDelete(ids).then( res => {
					if(res.code === 200){

					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	
</style>
