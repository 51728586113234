<template>
	<el-dialog
	        title="商品排序"
	        :visible.sync="sortData.type"
	        width="60%"
	        :before-close="handleClose">
		<avue-form ref="form"
			:option="option"
			v-model="form"
			@submit="formSubmit">
        </avue-form>
	</el-dialog>
</template>

<script>
    import { productSortBatchUpdate  } from "@/api/product/list";
	export default {
		props: {
			sortData: Object
		},
		data(){
			return{
				form: {
					ids: this.sortData.list
				},
				option: {
					emptyBtn: false,
				    labelWidth: 120,
					column: [
					    {
					        label: '排序',
					        span: 24,
					        prop: 'sort',
							type: 'number',
					        rules: [{
					            required: true,
					            message: "请输入排序值",
					            trigger: "blur"
					        }]
					    }
					]
				}
			}
		},
		methods: {
			formSubmit(form,done){
				// console.log(form)
				productSortBatchUpdate({
					sort: form.sort,
					ids: form.ids.toString()
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
						this.$root.eventHub.$emit('clickProductSlot', true);
					}else{
						this.$message.success(res.msg)
					}
					done()
				})
			},
			handleClose(){
				this.$confirm('确认关闭？')
					.then(res => {
						this.$root.eventHub.$emit('clickProductSlot', false);
					})
			}
		}
	}
</script>

<style>
</style>
