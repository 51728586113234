<template>
    <div class="main-content">
        <project v-if="project" @goBack="goBack"/>

        <Code v-if="!project" @goBack="goBack" :id="id"/>
    </div>
</template>

<script>
    import project from "./project";
    import Code from "./code";
    export default {
        name: "index",
        components: {
            project,
            Code
        },
        data() {
            return{
                project: true,
                id: ''
            }
        },
        methods: {
            goBack(e){
                this.project = e;
            }
        }
    }
</script>

<style scoped>

</style>