<template>
    <div class="main-content">
        <avue-crud :data="data" :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @selection-change="selectionChange"
                   @search-change="searchChange"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">

                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="row.state == 1 || item.name == '删除'"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.name == '删除' ? item.icon : item.name == '同意' ? 'el-icon-check': 'el-icon-close'"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

            </template>
			
			<template slot="avatarUrl" slot-scope="scope" >
				<img style="display: block;width: 40px;height: 40px;margin: 0 auto;" :src="scope.row.avatarUrl"></img>
			</template>
			

            <template slot="gender" slot-scope="scope" >
                <el-tag>{{scope.row.gender == 1 ? "男性" : scope.row.state == 2 ? "女性" : "未知"}}</el-tag>
            </template>

            <template slot="type" slot-scope="scope" >
                <el-tag v-if="scope.row.type == 1">普通用户</el-tag>
                <el-tag v-else-if="scope.row.type == 2">办事处用户</el-tag>
                <el-tag v-else>物业用户</el-tag>
            </template>

        </avue-crud>

    </div>
</template>

<script>
    import { officeRegionList } from '@/api/person/office';
    import { userList,userDelete } from '@/api/person/user';
    export default {
        name: "User",
        data(){
            return{
                selectionList: [],
                Buttons: [],
                search: {},
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    searchIndex:3,
                    searchIcon:true,
                    menuWidth: 100,
                    align: 'center',
                    column:[
                        {
                            label: '头像',
                            prop: 'avatarUrl',
							slot: true
                        },
                        {
                            label: '姓名',
                            prop: 'username',
                            search: true
                        },
                        {
                            label: '昵称',
                            prop: 'nickname',
                            search: true
                        },
                        {
                            label: '性别',
                            prop: 'gender',
                            type: 'select',
                            dicData: [
                                {
                                    label: "未知",
                                    value: '0'
                                },
                                {
                                    label: "男性",
                                    value: '1'
                                },
                                {
                                    label: "女性",
                                    value: '2'
                                }
                            ]
                        },
                        {
                            label: '邀请码',
                            prop: 'inviteCode',
                            search: true
                        },
                        {
                            label: '工号',
                            prop: 'jobNumber',
                            search: true
                        },
                        {
                            label: '手机号',
                            prop: 'phone',
                            search: true
                        },
                        {
                            label: '办事处名称',
                            prop: 'officeName',
                        },
                        {
                            label: '公司名称',
                            prop: 'companyName',
                            search: true
                        },
                        {
                            label: '国家',
                            prop: 'country'
                        },
                        {
                            label: '城市',
                            prop: 'city'
                        },
                        {
                            label: '表单城市',
                            prop: 'formCity',
                            search: true
                        },
                        {
                            label: '大区名称',
                            prop: 'regionName'
                        },
                        {
                            label: '大区',
                            prop: 'regionId',
                            search: true,
                            type: 'select',
                            hide: true,
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '类型',
                            prop: 'type',
                            type: 'select',
                            dicData: [
                                {
                                    label: "普通用户",
                                    value: '1'
                                },
                                {
                                    label: "办事处用户",
                                    value: '2'
                                },
                                {
                                    label: "物业用户",
                                    value: '3'
                                }
                            ],
                            slot:true,
                            search: true
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.GetOfficeRegionList();
        },
        methods: {
            GetOfficeRegionList(){
                officeRegionList().then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        const column = this.findObject(this.option.column, "regionId");
                        column.dicData = res.data;
                        this.$store.commit('SetRegionList',res.data)
                    }
                })
            },
            searchChange(params,done) {
                // console.log(params)
                this.search.companyName = params.companyName == undefined ? '':params.companyName;
                this.search.formCity = params.formCity == undefined ? '':params.formCity;
                this.search.inviteCode = params.inviteCode == undefined ? '':params.inviteCode;
                this.search.jobNumber = params.jobNumber == undefined ? '':params.jobNumber;
                this.search.nickname = params.nickname == undefined ? '':params.nickname;
                this.search.phone = params.phone == undefined ? '':params.phone;
                this.search.regionId = params.regionId == undefined ? '':params.regionId;
                this.search.type = params.type == undefined ? '':params.type;
                this.search.username = params.username == undefined ? '':params.username;
                this.onLoad();
                done();
            },
            clickMenu(name,form){
                // console.log(name,form)
                if(form == undefined){
                    this.rowDel(this.selectionList.toString())
                }else{
                    this.rowDel(form.id)
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                userDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetUserList();
            },
            GetUserList(){
                userList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    companyName: this.search.companyName,
                    formCity: this.search.formCity,
                    inviteCode: this.search.inviteCode,
                    jobNumber: this.search.jobNumber,
                    nickname: this.search.nickname,
                    phone: this.search.phone,
                    regionId: this.search.regionId,
                    type: this.search.type,
                    username: this.search.username,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>