<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form ref="form"
                   :option="option"
                   v-model="form"
                   :upload-error="uploadError"
                   :upload-delete="uploadDelete"
                   :upload-after="uploadAfter"
                   @submit="formSubmit">

        </avue-form>

    </el-dialog>
</template>

<script>
    import { homeAdd,homeView,homeUpdate } from '@/api/product/home';
    import { imageUrl,actionUrl } from '@/config/public';
    export default {
        name: "index",
        props: {
            title: {
                default: '新增',
                type: String
            },
            id: {
                default: '',
                type: String
            },
            parentId: {
                default: '0',
                type: String
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: {},
                option: {
                    submitBtn: this.title != '查看' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column:[
                        {
                            label: '图片',
                            type: 'upload',
                            listType: 'picture-img',
                            span: 12,
                            row: true,
                            prop: 'image',
                            loadText: '附件上传中，请稍等',
                            propsHttp:{
                                res:'data',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            rules: [{
                                required: true,
                                message: "请上传图片",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '排序',
                            prop: 'sort',
                            type: 'number',
                            rules: [{
                                required: true,
                                message: "请输入分类排序",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '标题',
                            prop: 'title',
                            rules: [{
                                message: "请输入分类标题",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '内容',
                            prop: 'subtitle',
                            rules: [{
                                message: "请输入分类内容",
                                trigger: "blur"
                            }],
                            disabled: this.title == '查看' ? true : false
                        }
                    ]
                }
            }
        },
        mounted() {
            if(this.title != '新增'){
                homeView(this.id).then( res => {
                    if(res.code === 200){
                        this.form = res.data;
                    }
                })
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    this.formSubmitAdd(form,done);
                }else{
                    this.formSubmitEdit(form,done);
                }
            },
            formSubmitAdd(form,done){
                // console.log(form)
                homeAdd({
                    image: form.image,
                    name: form.name,
                    title: form.title,
                    sort: form.sort,
					subtitle: form.subtitle
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeHome', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            formSubmitEdit(form,done){
                homeUpdate({
                    id: this.id,
                    image: form.image,
                    name: form.name,
                    title: form.title,
                    sort: form.sort,
					subtitle: form.subtitle
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeHome', true)
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeHome', false)
                    })
            },
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadAfter(res, done) {
                // this.$message.success('上传后的方法')'
                done()
                this.$nextTick(() => {
                    this.form.image = res.path;
                })
            },
            uploadError(error, column) {
                this.$message.error('上传失败')
                // console.log(error, column)
            }
        }
    }
</script>

<style scoped>

</style>