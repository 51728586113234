<template>
    <div class="main-content">
        <avue-crud :data="data"
                   :option="option"
                   v-model="form"
                   :page.sync="pageSync"
				   @search-change="searchChange"
				   @search-reset="searchReset"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

        </avue-crud>

        <ProductParam  v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :id="id"/>
    </div>
</template>

<script>
    import { paramList,paramDelete } from '@/api/product/param';
    import ProductParam from "@/components/product/param";
    export default {
        name: 'Param',
        components: {
            ProductParam
        },
        data() {
            return {
                Buttons: [],
                title: '',
                id: '',
                parentId: '',
                dialogVisible: false,
                form: {
                    image: ''
                },
				search: {},
                data: [],
                option:{
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    addBtn:false,
                    editBtn:false,
                    delBtn:false,
                    menuWidth: 300,
                    align: 'center',
                    searchMenuSpan: 6,
                    searchIndex:3,
                    searchIcon:true,
                    searchShowBtn:false,
                    column:[
                        {
                            label: '名称',
                            prop: 'name',
							search: true,
                            rules: [{
                                message: "请输入分类名称",
                                trigger: "blur"
                            }]
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;

            this.$root.eventHub.$on('changeParam', (n) => {
                this.dialogVisible = false
                if(n){
                    this.GetParamList();
                }
            })
        },
        methods: {
            onLoad(){
                this.GetParamList();
            },
            GetParamList() {
                paramList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
					name: this.search.name
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                });
            },
            clickMenu(name,form){
                // console.log(name,form)
                if(name != '删除'){
                    this.title = name;
                    this.dialogVisible = true;
                    return this.id = form == undefined ? '0' : form.id
                }else{
                    this.rowDel(form.id)
                }
            },
            rowDel(ids){
				this.$confirm(`删除这条数据`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					callback: action => {
						if(action == 'confirm'){
							paramDelete(ids).then( res => {
								// console.log(res)
								if(res.code === 200){
									this.$message.success(res.msg)
									this.onLoad();
								}else{
									this.$message.error(res.msg)
								}
							})
						}
					}
				})

            },
			searchChange(params,done){
				// console.log(params.name)/
				this.search = params
				this.GetParamList();
				done();
			},
			searchReset(params){
			    // console.log(params)/
				this.search.name = '';
				this.GetParamList();
			},
        }
    }
</script>

<style scoped>

</style>