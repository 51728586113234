<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit"></avue-form>
    </el-dialog>
</template>

<script>
    import { contactAdd,contactUpdate } from "@/api/system/contact";
    export default {
        name: "contact",
        props: {
            title: {
                default: '新增',
                type: String
            },
            projectViewForm: Object,
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: this.projectViewForm,
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label:'内容',
                            prop:'content',
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                            rules: [{
                                required: true,
                                message: "请输入角色名称",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'状态',
                            prop:'type',
                            type: 'radio',
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                            dicData: [{label: '微信',value: 1},{label: '电话',value: 2}],
                            rules: [{
                                required: true,
                                message: "请选择状态",
                                trigger: "blur"
                            }]
                        }
                    ]
                }
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    return this.formSubmitAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitUpdate(form,done)
                }
            },
            // 新增
            formSubmitAdd(form,done){
                contactAdd({
                    content: form.content,
                    type: form.type,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeContact', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitUpdate(form,done){
                contactUpdate({
                    id: form.id,
                    content: form.content,
                    type: form.type,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeContact', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeContact', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>