<template>
	<el-dialog
	        title="商品折扣"
	        :visible.sync="discountData.type"
	        width="60%"
	        :before-close="handleClose">
		<avue-form ref="form"
			:option="option"
			v-model="form"
			@submit="formSubmit">
        </avue-form>
	</el-dialog>
</template>

<script>
    import { productDiscountUpdate  } from "@/api/product/list";
	export default {
		props: {
			discountData: Object
		},
		data(){
			return{
				form: {
					ids: this.discountData.list
				},
				option: {
					emptyBtn: false,
				    labelWidth: 120,
					column: [
					    {
					        label: '折扣',
					        span: 24,
					        prop: 'discount',
							type: 'number',
					        rules: [{
					            required: true,
					            message: "请输入折扣",
					            trigger: "blur"
					        }]
					    }
					]
				}
			}
		},
		methods: {
			formSubmit(form,done){
				// console.log(form)
				productDiscountUpdate({
					discount: form.discount,
					ids: form.ids.toString()
				}).then(res => {
					if(res.code === 200){
						this.$message.success('修改成功')
						this.$root.eventHub.$emit('clickProductDiscount', true);
					}else{
						this.$message.success(res.msg)
					}
					done()
				})
			},
			handleClose(){
				this.$confirm('确认关闭？')
					.then(res => {
						this.$root.eventHub.$emit('clickProductDiscount', false);
					})
			}
		}
	}
</script>

<style>
</style>
