<template>
    <el-dialog
            title="产品列表"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">

        <avue-crud  :data="data"
                    :option="option"
                    :page.sync="pageSync"
                    :table-loading="loading"
                    @on-load="onLoad">

        </avue-crud>

    </el-dialog>
</template>

<script>
    import { configureProductList } from '@/api/configure/orderList';
    import { imageUrl } from '@/config/public';
    export default {
        name: "order",
        props: {
            id: {
                default: '0',
                type: String
            },
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                loading: true,
                data: [],
                option: {
                    addBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    menu:false,
                    align: 'center',
                    column: [
                        {
                            label:'产品图片',
                            prop:'image',
                            type: 'upload',
                            propsHttp:{
                                home: imageUrl
                            },
                        },
                        {
                            label:'产品名称',
                            prop:'name'
                        },
                        {
                            label:'产品数量',
                            prop:'num'
                        },
                        {
                            label:'价格',
                            prop:'price'
                        },
                        {
                            label: '规格',
                            prop:'spec'
                        },
                        {
                            // label: '联排数量',
                            label: '联排编号',
                            prop:'combinedRow'
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        methods:{
            onLoad(){
                this.GetConfigureProductList();
            },
            GetConfigureProductList(){
                configureProductList({
                    id: this.id,
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                        this.loading = false;
                    }
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeConfigureOrder', false)
                    })
            }
        }
    }
</script>

<style scoped>
.avue-crud >>> .avue-crud__menu{
    min-height: 0;
}
</style>
