<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch:{
    '$route'(to){
      this.$store.commit("setRouter",to.path);
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    }
  },
  created() {
    this.global.SET_sessionStorage();
  }
}
</script>

<style>
  @import  './style/public.css';
  @import  './style/font/iconfont.css';
  *{
    margin: 0;
    padding: 0;
  }
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>
