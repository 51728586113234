import {get, post, put,Delete} from '../axios'
/* 公告管理-列表 */
export const advertList = params => get('/web/system/advert/list',params);

// 公告管理-查看
export const advertView = params => get(`/web/system/advert/view/${params}`);

// 公告管理-修改
export const advertUpdate = params => put('/web/system/advert/update',params);

// 公告管理-新增
export const advertAdd = params => post('/web/system/advert/add',params);

// 公告管理-删除
export const advertDelete = params => Delete(`/web/system/advert/delete/${params}`);

// 广告管理-状态修改
export const advertSortUpdate = params => put('/web/system/advert/sort/update',params);