const user = {
    state: {
        userInfo: {},
        Token: '',
    },
    mutations: {
        setToken(state,info){
            state.Token = info
        },
        setUserInfo(state,info){
            state.userInfo = info
        }
    }

}
export default user