import { get,post,put,Delete } from '../axios'

// 办事处邀请码-列表
export const officeCodeList = params => get('/web/person/office/code/list',params);

// 办事处邀请码-新增
export const officeCodeAdd = params => post('/web/person/office/code/add',params);

// 办事处邀请码-查看
export const officeCodeView = params => get(`/web/person/office/code/view/${params}`);

// 办事处邀请码-修改
export const officeCodeUpdate = params => put('/web/person/office/code/update',params);

// 办事处邀请码-删除
export const officeCodeDelete = params => Delete(`/web/person/office/code/delete/${params}`);
