const office = {
    state: {
        regionList: []
    },
    mutations: {
        SetRegionList(state,info){
            state.regionList = info;
        }
    }
}
export default office
