import { get,Delete } from '../axios'

// 办事处申请-列表
export const officeApplyList = params => get('/web/person/office/apply/list',params);

// 办事处申请-通过
export const officeApplyAgree = params => get(`/web/person/office/apply/agree/${params}`);

// 办事处申请-拒绝
export const officeApplyRefuse = params => get(`/web/person/office/apply/refuse/${params}`);

// 办事处申请-删除
export const officeApplyDelete = params => Delete(`/web/person/office/apply/delete/${params}`);
