export default [
  {
    label: "通用图标",
    list: [
      "iconfont iconicon_roundadd",
      "iconfont iconicon_compile",
      "iconfont iconicon_glass",
      "iconfont iconicon_roundclose",
      "iconfont iconicon_roundreduce",
      "iconfont iconicon_delete",
      "iconfont iconicon_shakehands",
      "iconfont iconicon_task_done",
      "iconfont iconicon_voipphone",
      "iconfont iconicon_safety",
      "iconfont iconicon_work",
      "iconfont iconicon_study",
      "iconfont iconicon_task",
      "iconfont iconicon_subordinate",
      "iconfont iconicon_star",
      "iconfont iconicon_setting",
      "iconfont iconicon_sms",
      "iconfont iconicon_share",
      "iconfont iconicon_secret",
      "iconfont iconicon_scan_namecard",
      "iconfont iconicon_principal",
      "iconfont iconicon_group",
      "iconfont iconicon_send",
      "iconfont iconicon_scan",
      "iconfont iconicon_search",
      "iconfont iconicon_refresh",
      "iconfont iconicon_savememo",
      "iconfont iconicon_QRcode",
      "iconfont iconicon_im_keyboard",
      "iconfont iconicon_redpacket",
      "iconfont iconicon_photo",
      "iconfont iconicon_qq",
      "iconfont iconicon_wechat",
      "iconfont iconicon_phone",
      "iconfont iconicon_namecard",
      "iconfont iconicon_notice",
      "iconfont iconicon_next_arrow",
      "iconfont iconicon_left",
      "iconfont iconicon_more",
      "iconfont iconicon_details",
      "iconfont iconicon_message",
      "iconfont iconicon_mobilephone",
      "iconfont iconicon_im_voice",
      "iconfont iconicon_GPS",
      "iconfont iconicon_ding",
      "iconfont iconicon_exchange",
      "iconfont iconicon_cspace",
      "iconfont iconicon_doc",
      "iconfont iconicon_dispose",
      "iconfont iconicon_discovery",
      "iconfont iconicon_community_line",
      "iconfont iconicon_cloud_history",
      "iconfont iconicon_coinpurse_line",
      "iconfont iconicon_airplay",
      "iconfont iconicon_at",
      "iconfont iconicon_addressbook",
      "iconfont iconicon_boss",
      "iconfont iconicon_addperson",
      "iconfont iconicon_affiliations_li",
      "iconfont iconicon_addmessage",
      "iconfont iconicon_addresslist",
      "iconfont iconicon_add",
      "iconfont icongithub",
      "iconfont icongitee2",
    ]
  },
  {
    label: "系统图标",
    list: [
      "iconfont icon-zhongyingwen",
      "iconfont icon-caidan",
      "iconfont icon-rizhi1",
      "iconfont icon-zhuti",
      "iconfont icon-suoping",
      "iconfont icon-bug",
      "iconfont icon-qq1",
      "iconfont icon-weixin1",
      "iconfont icon-shouji",
      "iconfont icon-mima",
      "iconfont icon-yonghu",
      "iconfont icon-yanzhengma",
      "iconfont icon-canshu",
      "iconfont icon-dongtai",
      "iconfont icon-iconset0265",
      "iconfont icon-shujuzhanshi2",
      "iconfont icon-tuichuquanping",
      "iconfont icon-rizhi",
      "iconfont icon-cuowutishitubiao",
      "iconfont icon-debug",
      "iconfont icon-iconset0216",
      "iconfont icon-quanxian",
      "iconfont icon-quanxian",
      "iconfont icon-shuaxin",
      "iconfont icon-bofangqi-suoping",
      "iconfont icon-quanping",
      "iconfont icon-navicon",
      "iconfont icon-biaodan",
      "iconfont icon-liuliangyunpingtaitubiao08",
      "iconfont icon-caidanguanli",
      "iconfont icon-cuowu",
      "iconfont icon-wxbgongju",
      "iconfont icon-tuichu",
      "iconfont icon-daohanglanmoshi02",
      "iconfont icon-changyonglogo27",
      "iconfont icon-biaoge",
      "iconfont icon-baidu1",
      "iconfont icon-tubiao",
      "iconfont icon-souhu",
      "iconfont icon-msnui-360",
      "iconfont icon-iframe",
      "iconfont icon-huanyingye",
    ]
  }
]
