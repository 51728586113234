import {get, post, put,Delete} from '../axios'
/* 公告管理-列表 */
export const contactList = params => get('/web/system/contact/list',params);

// 公告管理-查看
export const contactView = params => get(`/web/system/contact/view/${params}`);

// 公告管理-修改
export const contactUpdate = params => put('/web/system/contact/update',params);

// 公告管理-新增
export const contactAdd = params => post('/web/system/contact/add',params);

// 公告管理-删除
export const contactDelete = params => Delete(`/web/system/contact/delete/${params}`);