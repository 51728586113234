<template>
    <el-dialog
            :title="'首页分类-' + classifyTitle"
            :visible.sync="dialogVisibleGoods"
            width="60%"
            :before-close="handleClose">

        <avue-crud :data="data"
                   ref="crud"
                   :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @row-update="handleUpdate"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button :type=" active ? '' : 'primary' "
                           :icon=" active ? 'el-icon-arrow-left' : 'el-icon-plus' "
                           size="small"
                           :plain=" active "
                           @click.stop="clickMenu">{{ active ? '返回' : '新增' }}</el-button>

            </template>

            <template slot="menu" slot-scope="{row,index}">


                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           icon="el-icon-plus"
                           v-if="active"
                           @click.stop="clickObtain('add',row)">添加</el-button>

                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           icon="el-icon-setting"
                           v-if="!active"
                           @click.stop="$refs.crud.rowEdit(row,index)">修改</el-button>

                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           icon="el-icon-delete"
                           v-if="!active"
                           @click.stop="clickObtain('delete',row)">移除</el-button>
            </template>

        </avue-crud>

    </el-dialog>
</template>

<script>
    import { homeProductList,homeProductAdd,homeProductDelete,homeSort } from "@/api/product/home"
    import { productList } from "@/api/product/list";
    import { imageUrl } from '@/config/public';
    export default {
        name: "goods",
        props: {
            classifyID: {
                default: '',
                type: String
            },
            classifyTitle: {
                default: '',
                type: String
            },
            dialogVisibleGoods: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                active: false,
                search:{},
                form:{},
                data: [],
                option:{
                    border: true,
                    align:'center',
                    menuAlign:'center',
                    addBtn: false,
                    editBtn: false,
                    delBtn:false,
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    menuWidth: 180,
                    column:[
                        {
                            label: '主图',
                            prop: 'mainGraph',
                            type: 'upload',
                            listType: 'picture-img',
                            width: 100,
                            propsHttp:{
                                home: imageUrl
                            },
                            hide: false,
                            display: false
                        },
                        {
                            label:'名称',
                            prop:'name',
                            hide: false,
                            display: false
                        },
                        {
                            label:'排序',
                            prop:'sort',
                            type: 'number',
                            hide: true,
                            display: true
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        methods: {
            onLoad(){
                if(this.active){
                    this.list()
                }else{
                    this.GetHomeProductList();
                }
            },
            clickObtain(type,form){
                // console.log(form)
                this.$confirm(`${type === 'add' ? '添加':'移除'}这条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            if(type === 'add'){
                                this.GetHomeProductAdd(form.id);
                            }else{
                                this.GetHomeProductDelete(form.id);
                            }
                        }
                    }
                })
            },
            GetHomeProductAdd(id){
                homeProductAdd({
                    id: this.classifyID,
                    productIds: id
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.onLoad();
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            },
            GetHomeProductDelete(id){
                homeProductDelete(id).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.onLoad();
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            },
            handleUpdate(form,index,done){
                // this.$message.success('编辑数据'+ JSON.stringify(form)+'数据序号'+index);
                homeSort({
                    id: form.id,
                    sort: form.sort
                }).then( res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.GetHomeProductList();
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                })
            },
            clickMenu(){
                this.active = !this.active;
                this.data = [];
                this.pageSync = {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                }
                this.onLoad();
            },
            GetHomeProductList(){
                homeProductList({
                    id: this.classifyID,
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then(res => {
                    if (res.code === 200) {
                        // console.log(res);
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                });
            },
            list(){
                productList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name,
                    classifyId: this.search.classifyId,
                    seriesClassifyId: this.search.seriesClassifyId,
                    state: this.search.state,
                }).then(res => {
                    // console.log('商品列表',res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.currentPage = res.data.current;
                        this.pageSync.total = res.data.total;
                    }
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeGoods', false)
                    })
            },
        }
    }
</script>

<style scoped>

</style>