<template>
    <el-dialog title="新增/编辑"
               width="60%"
               v-dialogdrag
               :visible.sync="emailData.type"
               class="avue-dialog avue-dialog--top"
               :close-on-click-modal="false"
               :before-close="handleClose">
        <avue-form :option="option"
                   v-model="form" ref="form">

        </avue-form>
        <div class="avue-dialog__footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"
                       @click="handleSubmit(form)">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { emailAdd,emailView,emailUpdate } from "@/api/system/email"
    export default {
        name: "email",
        props: {
            emailData: Object
        },
        data(){
            return{
                form: {
                    state: 1
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label:'接收邮箱',
                            prop:'email',
                            type: 'input',
                            span: 18,
                            rules: [{
                                required: true,
                                message: "请输入接收邮箱",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'状态',
                            prop:'state',
                            type: 'radio',
                            span: 18,
                            dicData: [
                                {
                                    label: '启用',
                                    value: 1
                                },
                                {
                                    label: '禁用',
                                    value: 2
                                }
                            ],
                            rules: [{
                                required: true,
                                message: "请选择状态",
                                trigger: "blur"
                            }]
                        }
                    ]
                }
            }
        },
        mounted() {
            if(this.emailData.id != 0){
                this.GET_emailView();
            }
        },
        methods: {
            GET_emailView(){
                emailView(this.emailData.id).then(res=>{
                    console.log(res)
                    if(res.code === 200){
                        this.form = res.data;
                    }
                })
            },
            handleSubmit(form){
                console.log(form)
                this.$refs.form.validate((vaild,done)=>{
                    if(vaild){
                        if(this.emailData.id == 0){
                            this.GET_emailAdd(form,done);
                        }else{
                            this.GET_emailUpdate(form,done);
                        }
                    }
                })
            },
            GET_emailAdd(form,done){
                emailAdd({
                    email: form.email,
                    state: form.state,
                }).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeEmail', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            GET_emailUpdate(form,done){
                emailUpdate({
                    id: form.id,
                    email: form.email,
                    state: form.state,
                }).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeEmail', true);
                    }else{
                        this.$message.error(res.msg)
                    }
                    done();
                }).catch(err=>{
                    this.$message.error(err.msg)
                    done();
                })
            },
            handleClose(){
                this.$root.eventHub.$emit('changeEmail', false);
            }
        }
    }
</script>

<style scoped>

</style>