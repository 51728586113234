import { get,post,put,Delete } from '../axios'

// 首页分类-列表
export const homeList = params => get('/web/product/home/classify/list',params);

// 首页分类-新增
export const homeAdd = params => post('/web/product/home/classify/add',params);

// 首页分类-删除
export const homeDelete = params => Delete(`/web/product/home/classify/delete/${params}`);

// 首页分类-查看
export const homeView = params => get(`/web/product/home/classify/view/${params}`);

// 首页分类-修改
export const homeUpdate = params => put('/web/product/home/classify/update',params);

// 首页分类-产品-排序直接修改
export const homeSort = params => put('/web/product/home/classify/product/sort/update',params);

// 首页分类下-产品-列表
export const homeProductList = params => get('/web/product/home/classify/product/list',params);

// 首页分类下-产品-新增
export const homeProductAdd = params => post('/web/product/home/classify/product/add',params);

// 首页分类下-产品-删除
export const homeProductDelete = params => Delete(`/web/product/home/classify/product/delete/${params}`);

