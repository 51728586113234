<template>
    <div class="main-content">
        <avue-crud :data="data" :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @selection-change="selectionChange"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

        </avue-crud>

        <PersonCode v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :id="id"/>
    </div>
</template>

<script>
    import { officeCodeList,officeCodeDelete } from '@/api/person/code';
    import PersonCode from "@/components/person/office/code";
    export default {
        name: "Code",
        components:{
            PersonCode
        },
        data(){
            return{
                id: '0',
                title: '',
                dialogVisible: false,
                selectionList: [],
                Buttons: [],
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    align: 'center',
                    column:[
                        {
                            label: '类型',
                            prop: 'type',
                            type: 'radio',
                            dicData: [
                                {
                                    label: '办事处',
                                    value: 1
                                },
                                {
                                    label: '物业',
                                    value: 2
                                }
                            ]
                        },
                        {
                            label: '邀请码',
                            prop: 'code',
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                            width: 150
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeCode', (n) => {
                this.dialogVisible = false;
                if(n){
                    this.onLoad();
                }
            })
        },
        methods:{
            clickMenu(name,form){
                // console.log(name,form)
                if(name != '删除'){
                    this.title = name;
                    this.dialogVisible = true;
                    return this.id = form == undefined ? '0' : form.id
                }else{
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                officeCodeDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetOfficeCodeList();
            },
            GetOfficeCodeList(){
                officeCodeList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
