<template>
    <div class="main-content">
        <avue-crud
                :data="data"
                :option="option"
                :page.sync="pageSync"
                @selection-change="selectionChange"
                @on-load="onLoad"
                ref="crud">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="(item.name != '修改') && (item.name != '查看')"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
            </template>

            <template slot="position" slot-scope="scope" >
                <div>{{scope.row.position == 1 ? "首页轮播位置" : ""}}</div>
            </template>

            <template slot="state" slot-scope="scope" >
                <el-tag @click="stateTag(scope.row)" style="cursor: pointer">{{scope.row.state == 1 ? "上架" : "下架"}}</el-tag>
            </template>

        </avue-crud>

        <Advert v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :projectViewForm="projectViewForm"/>
    </div>
</template>

<script>
    import { advertList,advertDelete,advertView,advertSortUpdate } from "@/api/system/advert"
    import { imageUrl } from '@/config/public';
    import Advert from "@/components/system/advert/advert"
    export default {
        name: "advert",
        components: {
            Advert
        },
        data(){
            return{
                id: '',
                title: '',
                dialogVisible: false,
                projectViewForm: {},
                Buttons: [],
                data: [],
                option: {
                    emptyText: '暂无数据',
                    border:true,
                    selection:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    column:[
                        {
                            label: '图片',
                            prop: 'image',
                            type: 'upload',
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label: '名称',
                            prop: 'name',
                        },
                        {
                            label: '位置',
                            prop: 'position',
                            slot: true
                        },
                        {
                            label: '排序',
                            prop: 'sort'
                        },
                        {
                            label: '状态',
                            prop: 'state',
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                selectionList: [],
            }
        },
        mounted() {
            let that = this;
            that.Buttons = that.$store.state.index.menuSubButtons;
            this.$root.eventHub.$on('changeAdvert', (n) => {
                this.dialogVisible = false;
                this.projectViewForm = {};
                if(n) {
                    this.onLoad();
                }
            })
        },
        methods: {
            stateTag(form){
                // console.log(form)
                this.$confirm(`${form.state == 1 ? '下架': '上架'}条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            advertSortUpdate({
                                id: form.id,
                                state: form.state == 1 ? 2 : 1
                            }).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.onLoad();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            clickMenu(name,form){
                this.title = name;
                if(name == '新增'){
                    this.dialogVisible = true;
                }else if(name == '删除'){
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }else{
                    return this.GetAdvertView(form.id);
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                advertDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }
            },
            onLoad(){
                this.GetNoticeList();
            },
            GetNoticeList(){
                advertList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                }).then( res => {
                    this.data = res.data.records;
                    this.pageSync.total = res.data.total;
                    this.pageSync.currentPage = res.data.current;
                })
            },
            GetAdvertView(id){
                advertView(id).then( res => {
                    // console.log(res)
                    this.dialogVisible = true;
                    this.projectViewForm = res.data;
                    if(res.data.type == 0){
                        this.projectViewForm.type = ''
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>