<template>
    <div class="main-content">
        <avue-crud :data="data" :option="option"
                   v-model="form"
                   :page.sync="pageSync"
                   @selection-change="selectionChange"
                   @search-change="searchChange"
                   @on-load="onLoad">

            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增' || item.name == '删除'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">

                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="row.state == 1 || item.name == '删除'"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.name == '删除' ? item.icon : item.name == '同意' ? 'el-icon-check': 'el-icon-close'"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

            </template>
			
			<template slot="avatarUrl" slot-scope="scope" >
				<img style="display: block;width: 40px;height: 40px;margin: 0 auto;" :src="scope.row.avatarUrl"></img>
			</template>

            <template slot="state" slot-scope="scope" >
                <el-tag>{{scope.row.state == 1 ? "待审核" : scope.row.state == 2 ? "已审核" : "已拒绝"}}</el-tag>
            </template>

        </avue-crud>

    </div>
</template>

<script>
    import { officeApplyList,officeApplyDelete,officeApplyAgree,officeApplyRefuse } from '@/api/person/officeApply';
    import { officeRegionList } from '@/api/person/office';
    import { imageUrl } from '@/config/public';
    export default {
        name: "officeApply",
        data(){
            return{
                id: '0',
                title: '',
                dialogVisible: false,
                selectionList: [],
                Buttons: [],
                search: {},
                data: [],
                form: {},
                option: {
                    border: true,
                    viewBtn: false,
                    addBtn: false,
                    delBtn:false,
                    editBtn:false,
                    columnBtn:false,
                    refreshBtn:false,
                    selection: true,
                    searchMenuSpan: 6,
                    searchIndex:3,
                    searchIcon:true,
                    align: 'center',
                    column:[
                        {
                            label: '头像',
                            prop: 'avatarUrl',
							slot: true
                        },
                        {
                            label: '姓名',
                            prop: 'username',
                            search: true
                        },
                        {
                            label: '昵称',
                            prop: 'nickname',
                            search: true
                        },
                        {
                            label: '邀请码',
                            prop: 'inviteCode',
                            search: true
                        },
                        {
                            label: '工号',
                            prop: 'jobNumber',
                            search: true
                        },
                        {
                            label: '办事处名称',
                            prop: 'officeName',
                        },
                        {
                            label: '联系方式',
                            prop: 'phone',
                            search: true
                        },
                        {
                            label: '大区名称',
                            prop: 'regionName',
                        },
                        {
                            label: '大区',
                            prop: 'regionId',
                            search: true,
                            type: 'select',
                            hide: true,
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            type: 'select',
                            dicData: [
                                {
                                    label: "待审核",
                                    value: '1'
                                },
                                {
                                    label: "已审核",
                                    value: '2'
                                },
                                {
                                    label: "已拒绝",
                                    value: '3'
                                }
                            ],
                            slot:true,
                            search: true
                        }
                    ]
                },
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            }
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;

            this.GetOfficeRegionList();
        },
        methods: {
            GetOfficeRegionList(){
                officeRegionList().then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        const column = this.findObject(this.option.column, "regionId");
                        column.dicData = res.data;
                        this.$store.commit('SetRegionList', res.data)
                    }
                })
            },
            searchChange(params,done) {
                // console.log(params)
                this.search.inviteCode = params.inviteCode == undefined ? '':params.inviteCode;
                this.search.jobNumber = params.jobNumber == undefined ? '':params.jobNumber;
                this.search.nickname = params.nickname == undefined ? '':params.nickname;
                this.search.phone = params.phone == undefined ? '':params.phone;
                this.search.regionId = params.regionId == undefined ? '':params.regionId;
                this.search.state = params.state == undefined ? '':params.state;
                this.search.username = params.username == undefined ? '':params.username;
                this.onLoad();
                done();
            },
            clickMenu(name,form){
                // console.log(name,form)
                if(name == '同意'){
                    this.GetOfficeApplyAgree(form.id);
                }else if(name == '拒绝'){
                    this.GetOfficeApplyRefuse(form.id);
                }else if(name == '删除'){
                    if(form == undefined){
                        this.rowDel(this.selectionList.toString())
                    }else{
                        this.rowDel(form.id)
                    }
                }
            },
            selectionChange(list){
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.selectionList = array;
            },
            rowDel(ids){
                let length = this.selectionList.length;
                if(this.selectionList.length == 0 && ids == ''){
                    this.$message.error('请选择数据')
                }else{
                    this.$confirm(`删除${length == 0 ? '这':length}条数据`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        callback: action => {
                            if(action == 'confirm'){
                                officeApplyDelete(ids).then( res => {
                                    // console.log(res)
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                        this.onLoad();
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }
                        }
                    })
                }

            },
            onLoad(){
                this.GetOfficeApplyList();
            },
            GetOfficeApplyList(){
                officeApplyList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    inviteCode:this.search.inviteCode,
                    jobNumber:this.search.jobNumber,
                    nickname:this.search.nickname,
                    phone:this.search.phone,
                    regionId:this.search.regionId,
                    state:this.search.state,
                    username:this.search.username
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.total = res.data.total;
                        this.pageSync.currentPage = res.data.current;
                    }
                })
            },
            GetOfficeApplyAgree(id){
                this.$confirm(`确定同意`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            officeApplyAgree(id).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.GetOfficeApplyList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            GetOfficeApplyRefuse(id){
                this.$confirm(`确定拒绝`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            officeApplyRefuse(id).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.GetOfficeApplyList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>