<template>
    <div class="main-content">
        <avue-crud :data="data"
                   :option="option"
                   v-model="form">
            <template slot="menuLeft">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                           :icon="item.icon"
                           size="small"
                           :plain="item.name == '新增' ? false : true"
                           v-show="item.name == '新增'"
                           @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
            </template>

            <template slot="menu" slot-scope="{row}">
                <el-button v-for="(item,index) in Buttons" :key="index"
                           v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                           style="margin-left:10px;"
                           size="small"
                           type="text"
                           :icon="item.icon"
                           @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>

                <el-button style="margin-left:10px;"
                           size="small"
                           type="text"
                           icon="el-icon-plus"
                           @click.stop="clickMenu('新增子项',row)">新增子项</el-button>
            </template>

        </avue-crud>

        <ClassifyIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :classifyViewForm="classifyViewForm"/>
    </div>
</template>

<script>
    import { classifyList,classifyDelete,classifyView } from '@/api/product/classify';
    import { imageUrl } from '@/config/public';
    import ClassifyIndex from "@/components/product/classify/index";

    export default {
        name: 'classify',
        components: {
            ClassifyIndex
        },
        data() {
            return {
                Buttons: [],
                title: '',
                id: '',
                parentId: '',
                dialogVisible: false,
                classifyViewForm: {},
                form: {
                    image: ''
                },
                data: [],
                option:{
                    border: true,
                    columnBtn:false,
                    refreshBtn:false,
                    addBtn:false,
                    editBtn:false,
                    delBtn:false,
                    menuWidth: 300,
                    column:[
                        {
                            label: '排序',
                            prop: 'sort',
                            type: 'number',
                            rules: [{
                                required: true,
                                message: "请输入排序",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: '分类名称',
                            prop: 'name',
                            rules: [{
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: '图片',
                            prop: 'image',
                            type: 'upload',
                            listType: 'picture-img',
                            align: 'center',
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label: '创建时间',
                            prop: 'createTime',
                            display: false
                        }
                    ]
                },
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;

            this.GetClassifyList();
            this.$root.eventHub.$on('changeClassify', (n) => {
                this.dialogVisible = false;
                this.classifyViewForm = {};
                if(n){
                    this.GetClassifyList();
                }
            })
        },
        methods: {
            GetClassifyList() {
                classifyList().then(res => {
                    if (res.code === 200) {
                        // console.log(res);
                        this.data = res.data;
                    }
                });
            },
            clickMenu(name,form){
                // console.log(name,form)
                this.title = name;
                if(name == '删除'){
                    this.rowDel(form.id);
                }else if(name == '新增'){
                    this.dialogVisible = true;
                    this.classifyViewForm = {};
                }else{
                    this.GetClassifyView(form.id);
                }
            },
            rowDel(id){
                this.$confirm(`删除这条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            classifyDelete(id).then( res => {
                                console.log(res)
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.GetClassifyList();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            GetClassifyView(id){
                classifyView(id).then( res => {
                    if(res.code === 200){
                        this.classifyViewForm = res.data;
                        this.dialogVisible = true;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>