<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
        <avue-form :option="option" v-model="form" @submit="formSubmit">
            <template slot="content">
                <avue-ueditor v-model="form.content" :options="uOptions"></avue-ueditor>
            </template>
        </avue-form>
    </el-dialog>
</template>

<script>
    import { noticeAdd,noticeUpdate } from "@/api/system/notice";
    import { actionUrl } from '@/config/public';
    export default {
        name: "notice",
        props: {
            title: {
                default: '新增',
                type: String
            },
            projectViewForm: Object,
            dialogVisible: {
                default: false,
                type: Boolean
            }
        },
        data(){
            return{
                form: this.projectViewForm,
                option: {
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
						{
                            label:'标题',
                            prop:'title',
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                            rules: [{
                                required: true,
                                message: "请输入标题",
                                trigger: "blur"
                            }]
						},
                        {
                            label:'内容',
                            prop:'content',
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                            rules: [{
                                required: true,
                                message: "请输入内容",
                                trigger: "blur"
                            }]
                        },
                        {
                            label:'状态',
                            prop:'state',
                            type: 'radio',
                            span: 24,
                            disabled: this.title == '新增' || this.title == '修改' ? false : true,
                            dicData: [{label: '上架',value: 1},{label: '下架',value: 2}],
                            rules: [{
                                required: true,
                                message: "请选择状态",
                                trigger: "blur"
                            }]
                        }
                    ]
                },
                uOptions: {
                    action: actionUrl,
                    headers: {
                        Authorization: this.$store.state.user.Token
                    },
                    customConfig: {},
                    props: {
                        url: 'path',
                        res: 'data',
                        name: 'path'
                    }
                }
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    return this.formSubmitAdd(form,done)
                }
                if(this.title == '修改'){
                    return this.formSubmitUpdate(form,done)
                }
            },
            // 新增
            formSubmitAdd(form,done){
                // console.log(form)
                noticeAdd({
					title: form.title,
                    content: form.content,
                    state: form.state,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeNotice', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            // 修改
            formSubmitUpdate(form,done){
                noticeUpdate({
                    id: form.id,
					title: form.title,
                    content: form.content,
                    state: form.state,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeNotice', true)
                        done()
                    }else {
                        this.$message.error(res.msg)
                        done()
                    }
                }).catch(err => {
                    this.$message.error(err.msg)
                    done()
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeNotice', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>