<template>
    <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="70%"
            :before-close="handleClose">

        <avue-form ref="form"
                   :option="option"
                   v-model="form"
                   @submit="formSubmit">
				   
			<template slot="content">
				<avue-ueditor v-model="form.content" :options="uOptions"></avue-ueditor>
            </template>
			
        </avue-form>

    </el-dialog>
</template>

<script>
    import { introduceAdd,introduceUpdate } from "@/api/system/introduce";
	import { actionUrl,imageUrl } from "@/config/public.js"
    export default {
        name: "introduce",
        props: {
            title: String,
            dialogVisible: {
                default: false,
                type: Boolean
            },
            introduceViewForm: Object
        },
        data(){
            return{
                form: this.introduceViewForm == '' ? {} : this.introduceViewForm,
                option: {
                    labelWidth: 120,
                    submitBtn: this.title == '新增' || this.title == '修改' ? true : false,
                    emptyBtn: this.title == '新增' ? true : false,
                    column: [
                        {
                            label: '类型',
                            prop: 'type',
                            type: 'select',
                            span: 12,
                            dicData:[{
                                label:'使用指南',
                                value: 1
                            },{
                                label:'关于我们',
                                value: 2
                            }],
                            rules: [{
                                required: true,
                                message: "请选择类型",
                                trigger: "blur"
                            }],
                            display: true,
                            disabled: this.title == '查看' ? true : false
                        },
                        {
                            label: '内容',
                            span: 24,
                            prop: 'content',
							slot: true,
                            rules: [{
                                required: true,
                                message: "请输入内容",
                                trigger: "blur"
                            }],
                            display: true,
                            disabled: this.title == '查看' ? true : false
                        }
                    ]
                },
				uOptions: {
				    action: actionUrl,
				    headers: {
				        Authorization: this.$store.state.user.Token
				    },
				    customConfig: {},
				    props: {
				        url: "path",
				        res: "data",
				        name: 'path',
				        home: imageUrl,
				    }
				},
            }
        },
        methods: {
            formSubmit(form,done){
                if(this.title == '新增'){
                    this.formSubmitAdd(form,done);
                }else{
                    this.formSubmitEdit(form,done);
                }
            },
            formSubmitAdd(form,done){
                introduceAdd({
                    content: form.content,
                    type: form.type,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeIntroduce', true)
                        done();
                    }else{
                        this.$message.error(res.msg)
                        done();
                    }
                })
            },
            formSubmitEdit(form,done){
                introduceUpdate({
                    id: form.id,
                    content: form.content,
                    type: form.type,
                }).then( res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$message.success(res.msg)
                        this.$root.eventHub.$emit('changeIntroduce', true)
                        done();
                    }else{
                        this.$message.error(res.msg)
                        done();
                    }
                })
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(res => {
                        this.$root.eventHub.$emit('changeIntroduce', false)
                    })
            }
        }
    }
</script>

<style scoped>

</style>