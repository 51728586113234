<template>
    <div class="main-content">

        <div class="table-content" v-if="!dialogVisible">
            <avue-crud :data="data"
                       :option="option"
                       :search.sync="search"
                       :page.sync="pageSync"
                       @on-load="onLoad"
                       @search-change="searchChange"
                       @search-reset="searchReset"
                       ref="crud"
					   @sort-change="sortChange"
                       @selection-change="selectionChange">

                <template slot="menuLeft">

                    <el-button v-for="(item,index) in Buttons" :key="index"
                               :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                               :icon="item.icon"
                               size="small"
                               :plain="item.name == '新增' ? false : true"
                               v-show="(item.name != '修改') && (item.name != '查看')"
                               @click.stop="clickMenu(item.name)">{{item.name}}</el-button>

					<el-button type="info"
							size="small"
							@click.stop="clickSort()">排序</el-button>
					<el-button type="info"
							size="small"
							@click.stop="clickDiscount()">活动折扣</el-button>
					<el-button type="info"
							size="small"
							@click.stop="clickClassify()">产品分类</el-button>
					<el-button type="info"
							   size="small"
							   @click.stop="clickSeries()">系列分类</el-button>
                </template>

                <template slot="menu" slot-scope="{row}">
                    <el-button
                               v-show="row.state == 2"
                               style="margin-left:10px;"
                               size="small"
                               type="text"
                               icon="el-icon-upload2"
                               @click.stop="clickMenu('上架',row)">上架</el-button>
                    <el-button
                            v-show="row.state == 1"
                            style="margin-left:10px;"
                            size="small"
                            type="text"
                            icon="el-icon-download"
                            @click.stop="clickMenu('下架',row)">下架</el-button>

                    <el-button v-for="(item,index) in Buttons" :key="index"
                               v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                               style="margin-left:10px;"
                               size="small"
                               type="text"
                               :icon="item.icon"
                               @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
                </template>

				<template slot="discount" slot-scope="{row}">
					<div>{{row.discount}}%</div>
				</template>

            </avue-crud>
        </div>

        <productIndex v-if="dialogVisible" :dialogVisible="dialogVisible" :title="title" :id="id" :formData="formData"/>
		<Sort v-if="sortData.type" :sortData="sortData"></Sort>
		<Discount v-if="discountData.type" :discountData="discountData"></Discount>
		<Series v-if="seriesData.type" :seriesData="seriesData"></Series>
		<Classify v-if="classifyData.type" :classifyData="classifyData"></Classify>
    </div>
</template>

<script>
    import productIndex from "@/components/product/index";
    import { productList,productDelete,productStateUpdate,classifyHome,classifyTree,productTree,productView,productSortBatchUpdate  } from "@/api/product/list";
    import { imageUrl } from '@/config/public';
	import Sort from "@/components/product/sort";
	import Discount from "@/components/product/discount";
	import Series from "@/components/product/series";
	import Classify from "@/components/product/classify";
    export default {
        name: "list",
        components:{
            productIndex,
			Sort,
			Discount,
			Series,
			Classify
        },
        data() {
            return {
				sortData: {
					type: false,
					list: []
				},
				discountData: {
					type: false,
					list: []
				},
				seriesData: {
					type: false,
					list: []
				},
				classifyData: {
					type: false,
					list: []
				},
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
                Buttons: [],
                search:{},
                data: [],
                option:{
                    border:true,
                    stripe:false,
                    showHeader:true,
                    selection:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,
                    refreshBtn:false,
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    searchMenuSpan: 6,
                    searchIndex:3,
                    menuWidth: 300,
                    searchIcon:true,
                    searchShowBtn:false,
                    column:[
                        {
                            label:'商品图片',
                            prop:'mainGraph',
                            type: 'upload',
                            propsHttp:{
                                home: imageUrl
                            },
                        },
                        {
                            label:'商品名称',
                            prop:'name',
                            search: true,
                        },
                        {
                            label:'价格',
                            prop:'price',
                            // sortable:true,
                        },
                        {
                            label:'库存',
                            prop:'stock',
                            // sortable:true,
                        },
                        {
                            label:'活动折扣',
                            prop:'discount',
							slot: true
                            // sortable:true,
                        },
                        {
                            label:'销量',
                            prop:'volume',
                            // sortable:true,
                        },
                        {
                            label:'访问量',
                            prop:'readNum',
                            // sortable:true
                        },
                        {
                            label:'排序',
                            prop:'sort'
                        },
                        {
                            label:'创建时间',
                            prop:'createTime'
                        },
                        {
                            label:'产品分类',
                            prop:'classifyId',
                            search: true,
                            hide: true,
                            rules: [{
                                message: "请输入产品分类",
                                trigger: "blur"
                            }],
                            filter: false,
                            type: 'cascader',
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '系列分类',
                            prop: 'seriesClassifyId',
                            search: true,
                            hide: true,
                            rules: [{
                                message: "请输入系列分类",
                                trigger: "blur"
                            }],
                            filter: false,
							type: "cascader",
                            dicData: [],
                            props: {
                                label: "name",
                                value: 'id'
                            },
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            search: true,
                            type: 'radio',
                            hide: true,
                            dicData: [{label: '上架',value: 1},{label: '下架',value: 2}],
                            mock:{
                                type:'dic'
                            }
                        }
                    ]
                },

                name: '', // 请求false
                ids: [],
                id: '',
                title: '',
				formData: {},
                dialogVisible: false,
            };
        },
        mounted() {
            this.Buttons = this.$store.state.index.menuSubButtons;
            this.GetClassifyTree();
            this.GetProductTree();
            this.GetClassifyHome();

            this.$root.eventHub.$on('changeProduct', (n) => {
                this.dialogVisible = false
                if(n){
                    this.onLoad();
					this.formData = {
						form: {},
						uText: '',
						paramData: [],
						specsData: []
					}
                }
            })
			this.$root.eventHub.$on('clickProductSlot', (n) => {
			    this.sortData.type = false;
			    this.sortData.list = [];
			    if(n){
			        this.onLoad();
			    }
			})

			this.$root.eventHub.$on('clickProductDiscount', (n) => {
			    this.discountData.type = false;
			    this.discountData.list = [];
			    if(n){
			        this.onLoad();
			    }
			})


			this.$root.eventHub.$on('clickProductSeries', (n) => {
			    this.seriesData.type = false;
			    this.seriesData.list = [];
			    if(n){
			        this.onLoad();
			    }
			})

			this.$root.eventHub.$on('clickProductClassify', (n) => {
			    this.classifyData.type = false;
			    this.classifyData.list = [];
			    if(n){
			        this.onLoad();
			    }
			})
        },
        methods:{
			clickSort(){
				if(this.ids.length === 0){
					this.$message.error('请选择商品')
				}else{
					this.sortData.type = true;
					this.sortData.list = this.ids;
					// productSortBatchUpdate().then(res => {

					// })
				}
			},
			clickDiscount(){
				if(this.ids.length === 0){
					this.$message.error('请选择商品')
				}else{
					this.discountData.type = true;
					this.discountData.list = this.ids;
				}
			},
			// 系列分类
			clickSeries(){
				if(this.ids.length === 0){
					this.$message.error('请选择商品')
				}else{
					this.seriesData.type = true;
					this.seriesData.list = this.ids;
				}
			},
			// 产品分类
			clickClassify(){
				if(this.ids.length === 0){
					this.$message.error('请选择商品')
				}else{
					this.classifyData.type = true;
					this.classifyData.list = this.ids;
				}
			},
            onLoad(){
                this.list()
            },
            list(){
                productList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    name: this.search.name,
                    classifyId: this.search.classifyIdLast,
                    seriesClassifyId: this.search.seriesClassifyIdLast,
                    state: this.search.state,
                }).then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.data = res.data.records;
                        this.pageSync.currentPage = res.data.current;
                        this.pageSync.total = res.data.total;
                    }
                })
            },
            //首页分类
            GetClassifyHome(){
                classifyHome().then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        this.$store.commit('SetClassifyHome',res.data)
                    }
                })
            },
            // 产品分类
            GetProductTree(){
                productTree().then(res => {
                    // console.log(res)
                    if(res.code === 200){
                        const column = this.findObject(this.option.column, "classifyId");
                        column.dicData = res.data;
                        this.$store.commit('SetProductTree',res.data)
                    }
                })
            },
            // 系列分类
            GetClassifyTree(){
                classifyTree().then( res => {
                    if(res.code === 200){
                        const column = this.findObject(this.option.column, "seriesClassifyId");
                        column.dicData = res.data;
                        this.$store.commit('SetClassifyTree',res.data)
                    }
                })
            },
            searchChange(params,done) {
				// console.log(params.classifyId)
				if(params.classifyId != undefined){
					params.classifyId.forEach((item,index) => {
						if(index = params.classifyId.length - 1){
							this.search.classifyIdLast = item
						}
					})
				}else{
					this.search.classifyIdLast = ''
				}
				if(params.seriesClassifyId != undefined){
					params.seriesClassifyId.forEach((item,index) => {
						if(index = params.seriesClassifyId.length - 1){
							this.search.seriesClassifyIdLast = item
						}
					})
				}else{
					this.search.seriesClassifyIdLast = ''
				}
                this.list()
                done();
            },
            searchReset(params){
                // console.log(params)
				this.search.classifyIdLast = '';
				this.search.seriesClassifyIdLast = '';
            },
            clickMenu(name,form){
                // console.log(name,form)
				this.title = name;
				if(name == '新增'){
					this.dialogVisible = true;
				}else if(name == '删除'){
                    this.rowDel(form);
                }else if(name == '上架'){
                    this.GetProductStateUpdate(form.id,'1')
                }else if(name == '下架'){
                    this.GetProductStateUpdate(form.id,'2')
                }else{
					this.GetProductView(form.id);
                }
            },
            selectionChange(list){
                // console.log(list)
                let array = [];
                list.forEach(item => {
                    array.push(item.id)
                })
                this.ids = array;
            },
            GetProductStateUpdate(id,state){
                this.$confirm(`${state == 1 ? '上架':'下架'}这个商品`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            productStateUpdate({
                                id: id,
                                state: state
                            }).then( res => {
                                // console.log(res)
                                if(res.code === 200){
                                    this.$message.success(`${state == 1 ? '上架':'下架'}成功`)
                                    this.onLoad();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })

            },
            rowDel(form){
                if(form == undefined){
                    if(this.ids.length > 0){
                        this.UserDelete()
                    }else{
                        this.$message({
                            showClose: true,
                            message: '请选择一项数据',
                            type: 'warning'
                        });
                    }
                }else{
                    this.UserDelete(form.id)

                }
            },
            UserDelete(id){
                this.$confirm(`删除${ id == undefined ? this.ids.length : '这' }条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            productDelete( id == undefined ? this.ids : id ).then(res => {
                                // 获取数据成功后的其他操作
                                if(res.code === 200){
                                    this.$message.success(res.msg)
                                    this.onLoad();
                                }else{
                                    this.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
			sortChange(val){
				this.$message.success('查看控制台');
				// console.log(val);
			},
			GetProductView(id){
				productView(id).then( res => {
				    // console.log(res)
					this.formData = {
						form: res.data,
						uText: res.data.details,
						paramData: res.data.param,
						specsData: res.data.spec,
						specList: res.data.specList,
					}
					this.formData.form.seriesClassifyIdNewValue = res.data.seriesClassifyId;
					this.formData.form.seriesClassifyId = res.data.seriesStr;
					this.formData.form.classifyIdNewValue = res.data.classifyId;
					this.formData.form.classifyId = res.data.classifyStr;
					console.log(this.formData.form)
					this.dialogVisible = true;
					this.id = id;
				})
			}
        }
    }
</script>

<style scoped>

</style>
