import { get,post,put,Delete } from '../axios'

// 系列分类-列表
export const paramList = params => get('/web/product/param/list',params);

// 系列分类-新增
export const paramAdd = params => post('/web/product/param/add',params);

// 系列分类-查看
export const paramView = params => get(`/web/product/param/view/${params}`);

// 系列分类-修改
export const paramUpdate = params => put('/web/product/param/update',params);

// 系列分类-删除
export const paramDelete = params => Delete(`/web/product/param/delete/${params}`);