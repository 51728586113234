<template>
    <div class="main-content">

        <div class="table-content">
            <avue-crud
                    :data="data"
                    :option="option"
                    :search.sync="search"
                    :page.sync="pageSync"
                    @search-change="searchChange"
                    @search-reset="searchReset"
                    @selection-change="selectionChange"
                    @on-load="onLoad"
                    ref="crud">
                <template slot="menuLeft">
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               :type="item.name == '新增' ? 'primary': item.name == '删除' ? 'danger' : 'info'"
                               :icon="item.icon"
                               size="small"
                               :plain="item.name == '新增' ? false : true"
                               v-show="(item.name != '修改') && (item.name != '查看')"
                               @click.stop="clickMenu(item.name)">{{item.name}}</el-button>
                </template>

                <template slot="menu" slot-scope="{row}">
                    <el-button v-for="(item,index) in Buttons" :key="index"
                               v-show="(item.name == '删除') || (item.name == '修改') || (item.name == '查看')"
                            style="margin-left:10px;"
                            size="small"
                            type="text"
                            :icon="item.icon"
                            @click.stop="clickMenu(item.name,row)">{{item.name}}</el-button>
                </template>

                <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary"
                               icon="el-icon-circle-plus-outline"
                               size="small"
                               v-if="type=='add'"
                               @click="$refs.crud.rowSave()"
                    >保存</el-button>
                    <el-button type="primary"
                               icon="el-icon-check"
                               size="small"
                               v-if="type=='edit'"
                               @click="$refs.crud.rowUpdate()"
                    >修改</el-button>
                    <el-button
                               icon="el-icon-circle-close"
                               size="small"
                               plain
                               @click="$refs.crud.closeDialog()"
                    >取消</el-button>
                </template>

            </avue-crud>
        </div>

        <Role v-if="dialogVisible_role" :dialogVisible="dialogVisible_role" :choiceId="this.ids[0]" />
        <Edit v-if="dialogVisible_edit" :dialogVisible="dialogVisible_edit" :choiceId="choiceId"/>
        <Add v-if="dialogVisible_add" :dialogVisible="dialogVisible_add"/>
        <LookView v-if="dialogVisible_view" :dialogVisible="dialogVisible_view" :choiceId="choiceId"/>

    </div>
</template>

<script>
    import Role from '@/components/system/user/role';
    import Edit from '@/components/system/user/edit';
    import Add from '@/components/system/user/add';
    import LookView from '@/components/system/user/lookview';
    import { userlist, userDelete,passwordReset } from "@/api/system/index";
    import { imageUrl } from '@/config/public';
    import { mapActions } from 'vuex'

    export default {
        name: "role",
        components: {
            Add,
            Role,
            Edit,
            LookView
        },
        data() {
            return {
                Buttons: [],  //表格按钮
                search:{},
                data: [],
                option:{
                    emptyText: '暂无数据',
                    stripe:false,
                    showHeader:true,
                    index:true,
                    border:true,
                    selection:true,
                    align:'center',
                    menuAlign:'center',
                    columnBtn:false,  //列显隐
                    refreshBtn:false,  //刷新
                    saveBtn:false,
                    updateBtn:false,
                    cancelBtn:false,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    searchMenuSpan: 6,
                    column:[
                        {
                            label:'头像',
                            prop:'headPortrait',
                            type: 'upload',
                            span: 24,
                            row: true,
                            propsHttp:{
                                home: imageUrl
                            }
                        },
                        {
                            label:'登录账号',
                            prop:'username',
                            search: true
                        },
                        {
                            label:'用户姓名',
                            prop:'fullName',
                            search: true
                        },
                        {
                            label:'手机号码',
                            prop:'phone',
                            search: true
                        },
                        {
                            label:'角色名称',
                            prop:'roleName'
                        }
                    ]
                },
                ids: [], //选择数据

                dialogVisible_role: false,  //用户角色管理
                dialogVisible_edit: false,  //用户编辑
                dialogVisible_add: false,  //用户添加
                dialogVisible_view: false, //用户查看
                choiceId: '',
                page: 1,
                pageSize: 10,
                fullName: '',
                phone: '',
                username: '',

                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background:false,
                    pageSize: 10,
                },
            };
        },
        mounted() {
            let that = this;
            that.Buttons = that.$store.state.index.menuSubButtons
            // console.log(this.Buttons)

            that.getRole();  //用户管理-角色列表

            that.$root.eventHub.$on('changeRole', (n)=>{
                that.dialogVisible_role = false;
            })
            that.$root.eventHub.$on('changeEdit', (n)=>{
                that.dialogVisible_edit = false;
            })
            that.$root.eventHub.$on('changeAdd', (n)=>{
                that.dialogVisible_add = false;
                if(n){
                    that.user_list();
                }
            })
            that.$root.eventHub.$on('changeView', (n)=>{
                that.dialogVisible_view = false;
            })
        },
        methods:{
            ...mapActions(['getRole']),
            //用户管理-列表
            user_list(){
                userlist({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    fullName: this.fullName,
                    phone: this.phone,
                    username: this.username
                }).then(res => {
                    this.data = res.data.records;
                    this.pageSync.total = res.data.total;
                    this.pageSync.currentPage = res.data.current;
                })
            },
            onLoad(){
                this.user_list()
            },
            handleGrant(){
                let length = this.ids.length;
                if(length == 1){
                    this.dialogVisible_role = true
                }else {
                    this.$message.warning('最多选择一个用户');
                }
            },
            selectionChange(list){
                let array = [];
                if(list.length == 0){
                    this.ids = [];
                }else{
                    list.forEach(item => {
                        array.push(item.id);
                    })
                    this.ids = array;
                }
            },
            clickedit(form){
                this.choiceId = form.id;
                this.dialogVisible_edit = true;
            },
            clickMenu(name,form){
                // console.log(name,1111)
                if(name == '新增') return this.dialogVisible_add = true;
                if(name == '删除') return this.rowDel(form);
                if(name == '角色配置') return this.handleGrant()
                if(name == '修改') return this.clickedit(form);
                if(name == '查看') return this.clickView(form);
                if(name == '密码重置'){
                    // console.log(form)
                    this.PasswordReset();
                }
            },
            PasswordReset(){
                if(this.ids.length > 1 || this.ids.length == 0){
                    return this.$message({
                        showClose: true,
                        message: '请选择一项数据',
                        type: 'warning'
                    });
                }
                this.$confirm(`密码重置`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            if(this.ids.length == 1){
                                passwordReset(this.ids[0]).then(res => {
                                    if(res.code === 200){
                                        this.$message.success(res.msg)
                                    }else{
                                        this.$message.error(res.msg)
                                    }
                                })
                            }else{
                                this.$message({
                                    showClose: true,
                                    message: '请选择一项数据',
                                    type: 'warning'
                                });
                            }
                        }
                    }
                })
            },
            clickView(form){
                this.choiceId = form.id;
                this.dialogVisible_view = true;
            },
            rowDel(form){
                if(form == undefined){
                    if(this.ids.length > 0){
                        this.UserDelete()
                    }else{
                        this.$message({
                            showClose: true,
                            message: '请选择一项数据',
                            type: 'warning'
                        });
                    }
                }else{
                    this.UserDelete(form.id)

                }
            },
            UserDelete(id){
                let that = this;
                this.$confirm(`删除${ id == undefined ? this.ids.length : '这' }条数据`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if(action == 'confirm'){
                            userDelete( id == undefined ? this.ids : id ).then(res => {
                                // 获取数据成功后的其他操作
                                if(res.code === 200){
                                    that.user_list();
                                    that.$message.success(res.msg)
                                }else{
                                    that.$message.error(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            searchChange(params,done) {
                // console.log(params)
                this.fullName = params.fullName == undefined ? '':params.fullName;
                this.phone = params.phone == undefined ? '':params.phone;
                this.username = params.username == undefined ? '':params.username;
                this.user_list();
                done();
            },
            searchReset(params){
                this.fullName = params.fullName;
                this.phone = params.phone;
                this.username = params.username;
                this.user_list();
            }
        }
    }
</script>

<style scoped>

</style>